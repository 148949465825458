import React, {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import PageWrapper from "./PageWrapper";
import {useCookies} from "react-cookie";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player";
import {isMobile} from "react-device-detect";

export default function RumasuarPromotionPage(props) {
    const [isHideDescription, setHideDescription] = useState(false);
    const [smallScreen, setSmallScreen] = useState(false);
    const [isFirstButtonHovered, setFirstButtonHovered] = useState(false);
    const [isSecondButtonHovered, setSecondButtonHovered] = useState(false);
    const [isThirdButtonHovered, setThirdButtonHovered] = useState(false);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()
    const [carouselControls, setCarouselControls] =  useState(false)
    const isEN = cookie.lang !== "id";

    const [isPlaying, setIsPlaying] = useState(false)

    const handleResize = () => {
        console.log(window.innerWidth)
        if (window.innerWidth <= 575) {
            setSmallScreen(true)
        } else {
            setSmallScreen(false);
        }

        if (window.innerWidth <= 991) {
            setHideDescription(true);
        } else {
            setHideDescription(false);
        }
    }

    useEffect(() => {
        handleResize()

        setTimeout(()=>{
            setIsPlaying(true)
        }, 1000)

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    })

    return (
        <PageWrapper headerActive={'rumasuar-promotion'}>
            <Modal
                size="sm"
                onHide={()=>{
                    setIsLanguageInfoOpen(false)
                }}
                show={cookie.lang && isEN && isLanguageInfoOpen}
            >
                <Modal.Header closeButton>
                    Information
                </Modal.Header>
                <Modal.Body>
                    <div>
                        This page is only available in Indonesian.
                    </div>
                </Modal.Body>
            </Modal>

            <div src={'./image/star-bg.jpeg'} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                objectFit: 'cover',
                background : `url("./image/star-bg.jpeg")`
                // filter: 'blur(1px)'
            }}/>
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: '#FFFFFF1A'
            }}>
                <Container
                    style={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: smallScreen ? 'flex-start' : 'center',
                        marginTop: smallScreen ? 100 : 0,
                        flexDirection: 'column'
                    }}>
                    <Row>
                        <Carousel interval={null} controls={carouselControls}>
                            <Carousel.Item style={{
                                background : "black",
                                width : "100%",
                                flex : 1
                            }}>
                                <img src={'./image/main-poster-2.jpeg'}
                                     style={{
                                         width: '100%',
                                         height : 1,
                                         objectFit: 'contain',
                                     }}/>
                                <div style={{width : "100%"}}>
                                    <ReactPlayer
                                        // url={'https://www.youtube.com/watch?v=6qNPmTVBpNc'}
                                        url={require("../../asset/video puisi rumasuar.mp4")}
                                        height={'100%'}
                                        width={'100%'}
                                        config={{
                                            file : {
                                                attributes : {
                                                    style :{
                                                        objectFit : "contain",
                                                        height : "100%",
                                                        width : "100%",
                                                        background : 'rgba(0,0,0,0.0)'
                                                    }
                                                }
                                            }
                                        }}
                                        style={{
                                            overflow: 'hidden', backgroundColor: 'rgba(0,0,0,0.0)'}}
                                        playing={isPlaying}
                                        muted={false}
                                        controls={true}
                                        onEnded={()=>{
                                            setCarouselControls(true)
                                        }}
                                    />

                                </div>

                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={'./image/main-poster-2.jpeg'}
                                     style={{
                                         width: '100%',
                                         objectFit: 'contain',
                                         borderTopLeftRadius: smallScreen ? 0 : 15,
                                         borderTopRightRadius: smallScreen ? 0 : 15
                                     }}/>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={'./image/buku-rumasuar.jpeg'}
                                     style={{
                                         width: '100%',
                                         objectFit: 'contain',
                                         borderTopLeftRadius: smallScreen ? 0 : 15,
                                         borderTopRightRadius: smallScreen ? 0 : 15
                                     }}/>

                                <Carousel.Caption style={{backgroundColor: '#00000066', borderRadius: 5}}>
                                    <h3 style={{fontFamily: 'Mansalva-Regular', fontSize: '1.25em'}}>
                                        RUMASUAR
                                    </h3>
                                    {isHideDescription ? null :
                                        <p style={{fontFamily: 'Signika-Light', paddingLeft: 30, paddingRight: 30}}>
                                            Buku Rumasuar - 5 Poscard - Pembatas Buku - TTD Penulis
                                        </p>
                                    }
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={'./image/pundi-suar.jpeg'}
                                     style={{
                                         width: '100%',
                                         objectFit: 'contain',
                                         borderTopLeftRadius: smallScreen ? 0 : 15,
                                         borderTopRightRadius: smallScreen ? 0 : 15
                                     }}/>

                                <Carousel.Caption style={{backgroundColor: '#00000066', borderRadius: 5}}>
                                    <h3 style={{fontFamily: 'Mansalva-Regular', fontSize: '1.25em'}}>
                                        PUNDI SUAR
                                    </h3>
                                    {isHideDescription ? null :
                                        <p style={{fontFamily: 'Signika-Light', paddingLeft: 30, paddingRight: 30}}>
                                            Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku - TTD
                                            Penulis
                                        </p>
                                    }
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={'./image/pundi-suar-spesial.jpeg'}
                                     style={{
                                         width: '100%',
                                         objectFit: 'contain',
                                         borderTopLeftRadius: smallScreen ? 0 : 15,
                                         borderTopRightRadius: smallScreen ? 0 : 15
                                     }}/>

                                <Carousel.Caption style={{backgroundColor: '#00000066', borderRadius: 5}}>
                                    <h3 style={{fontFamily: 'Mansalva-Regular', fontSize: '1.25em'}}>
                                        PUNDI SUAR SPESIAL
                                    </h3>
                                    {isHideDescription ? null :
                                        <p style={{
                                            fontFamily: 'Signika-Light',
                                            paddingLeft: 30,
                                            paddingRight: 30
                                        }}>
                                            Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku -
                                            TTD Penulis - Tiket Virtual Launching Buku Rumasuar, 24 Agustus 2020, 15:30 WIB
                                        </p>
                                    }
                                </Carousel.Caption>
                            </Carousel.Item>

                            <Carousel.Item>
                                <img src={'./image/pundi-suar-intimate.jpeg'}
                                     style={{
                                         width: '100%',
                                         objectFit: 'contain',
                                         borderTopLeftRadius: smallScreen ? 0 : 15,
                                         borderTopRightRadius: smallScreen ? 0 : 15
                                     }}/>

                                <Carousel.Caption style={{backgroundColor: '#00000066', borderRadius: 5}}>
                                    <h3 style={{fontFamily: 'Mansalva-Regular', fontSize: '1.25em'}}>
                                        PUNDI SUAR INTIMATE
                                    </h3>
                                    {isHideDescription ? null :
                                        <p style={{fontFamily: 'Signika-Light', paddingLeft: 30, paddingRight: 30}}>
                                            Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku - TTD
                                            Penulis - Tiket Virtual Launching Buku Rumasuar, 24 Agustus 2020, 15:30 WIB
                                            - Tiket Intimate, 30 Agutus 2020, 10:00 WIB / 15:00 WIB
                                        </p>
                                    }
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Row>

                    <Row style={{
                        backgroundColor: '#ffffffE6',
                        boxShadow: '0px 2px 9px -1px rgba(0,0,0,0.75)',
                        borderBottomLeftRadius: smallScreen ? 0 : 5,
                        borderBottomRightRadius: smallScreen ? 0 : 5,
                        padding: 20,
                        paddingTop: 15,
                        paddingBottom: 15
                    }}>
                        <Col lg={4} md={12} style={{marginBottom : "0.5em", marginTop : "0.25em"}}>
                            <Button
                                onClick={() => props.history.push('/rumasuar/detail', {scrollTo: 'description'})}
                                onMouseDown={e => e.preventDefault()}
                                onMouseEnter={() => setFirstButtonHovered(true)}
                                onMouseLeave={() => setFirstButtonHovered(false)}
                                variant={"outlined"}
                                style={{
                                    width: '100%',
                                    opacity: isFirstButtonHovered ? .75 : 1,
                                    borderWidth: 0,
                                    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)',
                                    fontFamily: 'Signika-Regular'
                                }}>Deskripsi</Button>
                        </Col>
                        <Col lg={4} md={12} style={{marginBottom : "0.5em", marginTop : "0.25em"}}>
                            <Button
                                onClick={() => props.history.push('/rumasuar/detail', {scrollTo: 'buyBook'})}
                                onMouseDown={e => e.preventDefault()}
                                onMouseEnter={() => setSecondButtonHovered(true)}
                                onMouseLeave={() => setSecondButtonHovered(false)}
                                style={{
                                    opacity: isSecondButtonHovered ? .95 : 1,
                                    width: '100%',
                                    backgroundColor: '#434343',
                                    borderWidth: 0,
                                    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)',
                                    fontFamily: 'Signika-Regular'
                                }}>Beli Buku</Button>
                        </Col>
                        <Col lg={4} md={12} style={{marginBottom : "0.5em", marginTop : "0.25em"}}>
                            <Button
                                onClick={() => props.history.push('/rumasuar/detail', {scrollTo: 'buyBookAndTicket'})}
                                onMouseEnter={() => setThirdButtonHovered(true)}
                                onMouseLeave={() => setThirdButtonHovered(false)}
                                style={{
                                    opacity: isThirdButtonHovered ? .95 : 1,
                                    width: '100%',
                                    backgroundColor: '#434343',
                                    borderWidth: 0,
                                    boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.75)',
                                    fontFamily: 'Signika-Regular'
                                }}>Beli Buku + Tiket</Button>
                        </Col>

                    </Row>
                </Container>
            </div>
        </PageWrapper>
    )
}
