import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

export default function RumasuarPage(props) {
    const [isFirstCardHover, setFirstCardHover] = useState(false);
    const [isSecondCardHover, setSecondCardHover] = useState(false);
    const [isThirdCardHover, setThirdCardHover] = useState(false);
    const [isFourthCardHover, setFourthCardHover] = useState(false);
    const descriptionRef = useRef(null);
    const buyBookRef = useRef(null);
    const buyBookAndTicketRef = useRef(null);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [isWatchLaunchingBtnShown, setWatchLaunchingBtnShown] = useState(false);
    const [isWatchIntimateBtnShown, setWatchIntimateBtnShown] = useState(false);
    const [intimateId, setIntimateId] = useState(null);

    const handleResize = () => {
        console.log(window.innerHeight)
        setDimensions({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        new Session().getRumasuarSessions().then(res => {
            res.map(session => {
                if (session.id === 222 && session.status === 'ACCEPTED') {
                    //222: Launching
                    setWatchLaunchingBtnShown(true);
                } else if ((session.id === 223 || session.id === 224) && session.status === 'ACCEPTED') {
                    //223/224: Intimate I/II
                    setIntimateId(session.id)
                    setWatchIntimateBtnShown(true)
                }
            })
        }).catch(err => console.log(err))

        window.addEventListener('resize', handleResize)

        if (props.location.state) {
            if (props.location.state.scrollTo === 'description') window.scrollTo({
                left: 0,
                top: descriptionRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
            if (props.location.state.scrollTo === 'buyBook') window.scrollTo({
                left: 0,
                top: buyBookRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
            else if (props.location.state.scrollTo === 'buyBookAndTicket') window.scrollTo({
                left: 0,
                top: buyBookAndTicketRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const openSessionStream = async (id) => {
        try {
            const response = await new Session().getSessionStream(id);

            console.log(response)

            if (!response.expose) {
                alert('Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai')
            } else {
                window.open(response.stream_url, "_blank");
            }
        } catch(e){
            console.log(e)
        }
    }

    return (
        <PageWrapper
            headerActive={'rumasuar'}
            hideFooter
        >
            <Container fluid style={{
                background: `url('/image/rumasuar-decoration.jpeg')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: dimensions.width < 1500 ? '0px 0px' : `200px 544px`,
                backgroundPosition: 'calc(100% - 100px) 70%',
                backgroundAttachment: 'fixed'
            }}>
                <Container style={{
                    paddingTop: '1em', marginBottom: 70,
                }}>
                    <Modal
                        size="sm"
                        onHide={() => {
                            setIsLanguageInfoOpen(false)
                        }}
                        show={cookie.lang && isEN && isLanguageInfoOpen}
                    >
                        <Modal.Header closeButton>
                            Information
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                This page is only available in Indonesian.
                            </div>
                        </Modal.Body>

                    </Modal>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 60, marginBottom: 25}}>
                        <img src={require("../../asset/rumasuar_kutipan.jpg")}
                             style={{width: '100%', maxWidth: 900, borderRadius: 5, objectFit: "contain", maxHeight: "50vh"}}/>
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: 900,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 0%",
                        }}>

                            <p style={{
                                fontSize: '1.3em',
                                fontWeight: 'bolder',
                                fontFamily: 'Mansalva-Regular'
                            }} ref={descriptionRef}>DESKRIPSI</p>

                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>JUDUL</p>

                                <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>
                                    Rumasuar
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>KARYA</p>

                                <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>
                                    Bentara Bumi
                                </div>

                            </div>

                            <div style={{fontFamily: 'Signika-Light', marginBottom: 35}}>
                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>
                                    SINOPSIS
                                </p>

                                Dalam berpuisi, Bumi menerapkan proses-proses yang terdiri dari pelepasan (releasing),
                                pembingkaian kembali (reframing), pelemasan (relaxing), untuk mengenali, menemukan dan
                                menggali lebih dalam tentang diri. Dari berbagai proses tersebut, terciptalah sekumpulan
                                puisi dalam buku Rumasuar ini. Rumasuar merupakan rumah bagi puisi-puisi Bumi yang
                                dibagi ke dalam 10 bab: Mentari Bumi, Dwipta Jingga, Urip, Eros, 23, Dianingtyas,
                                Bentara, Suwung, Manungsa, dan Ruma.
                                <br/><br/>
                                Rumasuar hadir dengan spirit, to live is to give light. Oleh sebab itu, untuk setiap
                                pemesanan Rumasuar, sebagian keuntungannya akan disumbangkan ke Perihal Hewan.
                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}} ref={buyBookRef}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>
                                    PAKET <span style={{fontFamily: 'Signika-Light', fontSize: '.9em', color: 'grey'}}>(Tekan paket untuk diarahkan ke halaman toko)</span>
                                </p>

                                <Row>
                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10}}
                                         onClick={() => window.open('https://www.bukuindie.com/p/rumasuar/', "_blank")}>
                                        <div style={{
                                            cursor: 'pointer',
                                            boxShadow: isFirstCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}
                                             onMouseEnter={() => setFirstCardHover(true)}
                                             onMouseLeave={() => setFirstCardHover(false)}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={'../../image/buku-rumasuar.jpeg'} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isFirstCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Rumasuar
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp87.500
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Signika-Light', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Light',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Rumasuar - 5 Poscard - Pembatas Buku - TTD Penulis
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10, cursor: 'pointer'}}
                                         onClick={() => window.open('https://www.bukuindie.com/p/pundi-suar/', "_blank")}
                                         onMouseEnter={() => setSecondCardHover(true)}
                                         onMouseLeave={() => setSecondCardHover(false)}>
                                        <div style={{
                                            boxShadow: isSecondCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={'../../image/pundi-suar.jpeg'} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isSecondCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Pundi Suar
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp250.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Signika-Light', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Light',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku - TTD
                                                Penulis
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10, cursor: 'pointer'}}
                                         onClick={() => window.open('https://www.bukuindie.com/p/pundi-suar-spesial/', "_blank")}
                                         onMouseEnter={() => setThirdCardHover(true)}
                                         onMouseLeave={() => setThirdCardHover(false)}
                                         ref={buyBookAndTicketRef}>
                                        <div style={{
                                            boxShadow: isThirdCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={'../../image/pundi-suar-spesial.jpeg'} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isThirdCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Pundi Suar Special
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp300.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Signika-Light', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Light',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku - TTD
                                                Penulis - Tiket Virtual Launching Buku Rumasuar, 23 Agustus 2020, 15:30
                                                WIB
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10, cursor: 'pointer'}}
                                         onClick={() => window.open('https://www.bukuindie.com/p/pundi-suar-intimate/', "_blank")}
                                         onMouseEnter={() => setFourthCardHover(true)}
                                         onMouseLeave={() => setFourthCardHover(false)}>
                                        <div style={{
                                            boxShadow: isFourthCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={'../../image/pundi-suar-intimate.jpeg'} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isFourthCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Pundi Suar Intimate
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp500.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Signika-Light', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Light',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Rumasuar - Kaus - Totebag - Pouch - 5 Poscard - Pembatas Buku - TTD
                                                Penulis - Tiket Virtual Launching Buku Rumasuar, 23 Agustus 2020, 15:30
                                                WIB
                                                - Tiket Intimate, 30 Agutus 2020, 10:00 WIB / 15:00 WIB
                                            </div>
                                        </div>
                                    </Col>


                                </Row>
                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>DURASI</p>

                                <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>
                                    - 2 Jam (Virtual Launching Buku Rumasuar)<br/>
                                    - 2 Jam (Intimate)
                                </div>

                            </div>

                            <div
                                style={{
                                    // marginTop: 15,
                                    marginBottom: 35,
                                    // paddingBottom: 15,
                                    // paddingTop: 15,
                                    textAlign: 'left',
                                }}>

                                <div style={{
                                    marginBottom: 35, textAlign: 'left',

                                }}>

                                    <p style={{
                                        fontSize: '1.1em',
                                        fontWeight: 'bolder',
                                        textAlign: 'left',
                                        fontFamily: 'Mansalva-Regular'
                                    }}>WAKTU</p>

                                    <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>
                                        - 8-17 Agustus 2020 (Prapesan)<br/>
                                        - 23 Agustus 2020 (Virtual Launching Buku Rumasuar)<br/>
                                        - 30 Agustus 2020, pukul 10.00 dan 15:00 (Intimate - 2 sesi)
                                    </div>

                                </div>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>LOKASI</p>

                                <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>
                                    - Daring - Google Meet (Virtual Launching Buku Rumasuar)<br/>
                                    - Daring - Google Meet (Intimate)
                                </div>

                            </div>

                            {/*{isEN ? "Is Supported By patjarmerah, Kotasis, dan Intellivent" : "Didukung oleh patjarmerah, Kotasis, dan Intellivent"}*/}

                            <div style={{marginTop: 30, marginBottom: 200, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Mansalva-Regular'
                                }}>DIDUKUNG OLEH</p>

                                <div style={{marginTop: -5, fontFamily: 'Signika-Light'}}>

                                            <span>
                                                <a
                                                    href={'https://www.instagram.com/intelliventid/'}
                                                    target={'_blank'}>Intellivent</a>, <a
                                                href={'https://www.patjarmerah.com/'}
                                                target={'_blank'}>patjarmerah</a>,  <a
                                                href={'https://www.bukuindie.com/'}
                                                target={'_blank'}>Toko Buku Indie</a>, A Bag Studio, dan
                                                <a
                                                    href={'https://www.instagram.com/perihal.hewan/'}
                                                > Perihal Hewan
                                                </a>
                                            </span>
                                </div>

                            </div>
                        </div>
                    </Row>
                </Container>

                <Row style={{
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: 'white',
                    width: '100%',
                    borderTop: '1px solid #e6e6e6',
                    paddingTop: 25,
                    paddingBottom: 30,
                    paddingRight: 20,
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    {isWatchIntimateBtnShown ?
                        <Col xs={12} md={6} lg={3}>
                            <Button
                                style={{
                                    fontFamily: 'Signika-Semibold',
                                    fontSize: '1.05em',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    margin: 3,
                                    width: '100%',
                                    height: '55px',
                                }} onClick={() => openSessionStream(intimateId)}
                                variant={'outline-primary'}>
                                TONTON INTIMATE
                            </Button>
                        </Col> : null
                    }

                    {isWatchLaunchingBtnShown ?
                        <Col xs={12} md={6} lg={3}>
                            <Button
                                style={{
                                    fontFamily: 'Signika-Semibold',
                                    fontSize: '1.05em',
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    margin: 3,
                                    width: '100%',
                                    height: '55px',
                                }} onClick={() => openSessionStream(222)}
                                variant={'outline-primary'}>
                                TONTON VIRTUAL LAUNCHING
                            </Button>
                        </Col> : null
                    }

                    <Col xs={12} md={6} lg={3}>
                        <Button
                            style={{
                                fontFamily: 'Signika-Semibold',
                                fontSize: '1.05em',
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 3,
                                width: '100%',
                                height: '55px',
                            }} onClick={() => window.scrollTo({
                            left: 0,
                            top: buyBookAndTicketRef.current.offsetTop - 200,
                            behavior: 'smooth'
                        })}
                            variant={'outline-primary'}>
                            BELI BUKU + TIKET
                        </Button>
                    </Col>

                    <Col xs={12} md={6} lg={3}>
                        <Button
                            style={{
                                fontFamily: 'Signika-Semibold',
                                fontSize: '1.05em',
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 3,
                                width: '100%',
                                height: '55px',
                            }}
                            onClick={() => window.scrollTo({
                                left: 0,
                                top: buyBookRef.current.offsetTop - 200,
                                behavior: 'smooth'
                            })}
                        >
                            BELI BUKU
                        </Button>
                    </Col>
                </Row>

            </Container>
        </PageWrapper>
    )
}
