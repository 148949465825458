import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import React, {useEffect} from "react";
import {MdError} from "react-icons/md";
import moment from "moment";
import Session from "../../models/Session";
import {FaInfoCircle} from "react-icons/fa";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {useCookies} from "react-cookie";
import Container from "react-bootstrap/Container";

const enLocale = require('moment/locale/en-gb')
const idLocale = require('moment/locale/id')


export default function PapermoonTicketNode(props) {
    const {isEN, lineSeparator, session} = props;
    const [cookie, setCookie, removeCookie] = useCookies()

    if (isEN) {
        moment.updateLocale('en-gb', enLocale)
    } else {
        moment.updateLocale('id', idLocale)
    }

    const buyTicket = () => {
        new Session().buyTicket(session.id, isEN).then(res => {
            console.log(res)
            if (res.success) {
                if (res.registration_status === 'PENDING') {
                    if(isEN){
                        alert('Tiket for this session is sold out. You will be notified via e-mail if there is new ticket available.')
                    }else{
                        alert('Tiket untuk sesi ini sudah habis terjual. Anda akan dikabarkan melalui e-mail apabila kuota sudah tersedia.')
                    }
                    window.location.reload()
                } else window.open(res.snap_payment_url, "_self")
            }
        }).catch(err => console.log(err))
    }

    const handleExpiredPayment = () => {
        new Session().cancel(session.id).then(res => {
            if (res.success) buyTicket()
        }).catch(err => console.log(err))
    }

    const getStreamUrl = () => {
        new Session().getSessionStream(session.id).then(res => {
            if (!res.expose) alert(isEN ? 'Link not available yet! Please try again 3 hours before the session begins.' : 'Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai.')
            else {
                window.location.href = `/papermoon/watch?id=${session.registration_info.access_id}`
                // window.open(res.stream_url, "_self")
            }
        }).catch(err => console.log(err))
    }


    const changePaymentMethod = () => {
        new Session().changePaymentMethod(session.id).then(res => {
            console.log(res)
            if(res.success) window.open(res.snap_payment_url, "_self")
        }).catch(err => console.log(err))
    }

    return (
        <>
            <Row style={{marginTop: 25}}>
                <Col style={{
                    fontWeight: '600',
                    display: 'flex',
                    // alignItems: 'center',
                    flexDirection: 'column'
                }} xs={12} md={7}>
                    <div>
                        {moment(session.start_time).format('dddd, D MMMM YYYY')}<br/>

                        {moment(session.start_time).utc().add(7, 'hours').format('H:mm')} Yogya/BKK,
                        {moment(session.start_time).utc().subtract(4, 'hours').format(' H:mm')} NYC,
                        {moment(session.start_time).utc().add(2, 'hours').format(' H:mm')} Ams/Berlin,
                        {moment(session.start_time).utc().add(9, 'hours').format(' H:mm')} Tokyo,
                        {moment(session.start_time).utc().add(10, 'hours').format(' H:mm')} Melb,
                        {moment(session.start_time).utc().add(8, 'hours').format(' H:mm')} Sing/Taipei/KL/HK
                    </div>


                    {session.status || (!session.status && session.remaining_quota < 30) || (!session.status && !session.allow_registration) ?
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                                <Tooltip style={{
                                    opacity: (!session.status && session.remaining_quota < 30) || session.status === 'ACCEPTED' || (!session.status && !session.allow_registration) ? 0 : 1,
                                }}>
                                    {isEN && session.status === 'WAITING' ? 'Please pay before \n' : !isEN && session.status === 'WAITING' ? 'Harap bayar sebelum \n' : null}
                                    {isEN && session.status === 'REJECTED' ? 'Your payment has passed the deadline: \n' : !isEN && session.status === 'REJECTED' ? 'Pembayaran Anda telah melewati batas waktu: \n' : null}
                                    {session.status === 'WAITING' || session.status === 'REJECTED' ?
                                        <strong>{moment(session.registration_info.modified_at).add(24, 'hours').format('dddd, D MMMM YYYY, HH:mm')}.</strong> : null}
                                    {isEN && session.status === 'PENDING' ? 'Tickets for this session are sold out. You will be notified by e-mail when a quota is available.' : !isEN && session.status === 'PENDING' ? 'Tiket untuk sesi ini sudah habis terjual. Anda akan dikabarkan melalui e-mail apabila kuota sudah tersedia.' : null}
                                </Tooltip>
                            }
                        >
                            <div style={{
                                display: 'flex',
                                color: session.status === 'REJECTED' || (!session.status && session.remaining_quota < 30) || (!session.status && !session.allow_registration) ? 'red' : session.status === 'ACCEPTED' ? 'green' : '#99cc33',
                                marginTop: 4,
                                cursor: (!session.status && session.remaining_quota < 30) || session.status === 'ACCEPTED' || (!session.status && !session.allow_registration) ? 'auto' : 'pointer',
                                alignSelf: 'flex-start',
                            }}>
                                <FaInfoCircle style={{marginRight: 10, marginTop: 6}}/>
                                {isEN && session.status === 'WAITING' ? 'Waiting for Payment' : !isEN && session.status === 'WAITING' ? 'Menunggu Pembayaran' : null}
                                {isEN && session.status === 'PENDING' ? 'In Queue' : !isEN && session.status === 'PENDING' ? 'Dalam Antrean' : null}
                                {isEN && session.status === 'REJECTED' ? 'Payment Expired' : !isEN && session.status === 'REJECTED' ? 'Pembayaran Kadaluarsa' : null}
                                {isEN && session.status === 'ACCEPTED' ? 'Registered' : !isEN && session.status === 'ACCEPTED' ? 'Terdaftar' : null}
                                {isEN && !session.status && !session.allow_registration ? 'Registration is Closed' : !isEN && !session.status && !session.allow_registration ? 'Pendaftaran Tutup' : null}
                                {isEN && !session.status && session.remaining_quota !== 0 && session.remaining_quota < 30 && session.allow_registration ? `${session.remaining_quota} ticket(s) left` : !isEN && !session.status && session.remaining_quota !== 0 && session.remaining_quota < 30 && session.allow_registration ? `${session.remaining_quota} tiket tersisa` : null}
                                {isEN && !session.status && session.remaining_quota === 0 && session.allow_registration ? 'Sold out' : !isEN && !session.status && session.remaining_quota === 0 && session.allow_registration ? 'Habis terjual' : null}
                            </div>
                        </OverlayTrigger> : null
                    }
                </Col>

                <Col xs={12} md={5}>
                    <Container>
                        <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                disabled={session.status === 'PENDING' || (!session.status && !session.allow_registration) || (session.status === "REJECTED" && !session.allow_registration)}
                                onClick={() => {
                                    if (!cookie.token) props.showLoginForm(true)
                                    else if (session.status === 'ACCEPTED') getStreamUrl()
                                    else if (session.status === 'WAITING') window.open(session.registration_info.snap_payment_url)
                                    else if (session.status === 'REJECTED') handleExpiredPayment()
                                    else if (session.status !== 'PENDING') buyTicket()
                                }}
                                style={{
                                    fontWeight: 'bolder',
                                    alignSelf: 'flex-start',
                                    width: 125,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    margin: 15,
                                    marginTop: 15,
                                    // marginRight: 33,
                                    marginBottom: 7,
                                }}
                                variant={'primary'}
                            >
                                {isEN && session.status === 'WAITING' ? 'PAY' : !isEN && session.status === 'WAITING' ? 'BAYAR' : null}
                                {isEN && session.status === 'ACCEPTED' ? 'WATCH' : !isEN && session.status === 'ACCEPTED' ? 'TONTON' : null}
                                {isEN && session.status !== 'WAITING' && session.status !== 'ACCEPTED' ? 'BUY' : !isEN && session.status !== 'WAITING' && session.status !== 'ACCEPTED' ? 'BELI' : null}
                            </Button>
                        </Row>
                        {session.status === 'WAITING' ?

                            <Row style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: '#bfbfbf',
                                fontSize: 16,
                                fontWeight: '700',
                                cursor: 'pointer'
                            }} onClick={changePaymentMethod}>
                                {isEN ? 'Change Payment Method' : 'Ganti Metode Pembayaran'}
                            </Row>
                        : null}
                    </Container>
                </Col>
            </Row>

            {!lineSeparator ? null :
                <Row style={{
                    height: 1,
                    backgroundColor: '#e6e6e6',
                    marginTop: 20,
                    marginBottom: 20
                }}/>
            }
        </>
    )
}
