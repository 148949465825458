export default {
    moneyFormatter : function(nominal){

        let initialString = nominal + "";
        let fullString = ""

        let counter = 0;

        for(let i = initialString.length - 1; i >= 0; i--){
            fullString = initialString.charAt(i) + (counter%3===0 && counter > 0 ? "." : "") + fullString
            counter++;
        }

        return fullString

    }
};
