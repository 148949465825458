import {Button, Col, Container, Modal, Row} from "react-bootstrap";
import React, {Component, useEffect, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import Palette from "../../util/Palette";
import {
    isSafari, isMobileSafari
} from "react-device-detect";
import {useCookies} from "react-cookie";

export default function LoginModal(props) {
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    const [loginMode, setLoginMode] = useState(true);
    const [loginCred, setLoginCred] = useState({});
    const [registerCred, setRegisterCred] = useState({date_of_birth: "1997-01-01"});
    const [registerState, setRegisterState] = useState({...defaultRegisterState});
    const [error, setError] = useState(null);
    const [isDeviceSafari, setSafari] = useState(isSafari || isMobileSafari)

    const handleSubmit = async () => {
        setError(null);

        setRegisterState({
            ...defaultRegisterState
        })

        if (loginMode) {
            let userModel = new User();
            console.log(loginCred)


            if (!loginCred.email || !loginCred.password) {
                setError(isEN ? 'Please fill all available fields' : "Harap lengkapi email dan kata sandi");

                return
            }

            try {
                let result = await userModel.login(loginCred)
                props.onHide(result.token)
                if (props.customOnHide) props.customOnHide();
                setTimeout(function () {
                    window.location.reload();
                }, 500);
                console.log(result)
            } catch (e) {

                let msg = isEN ? 'An error occured' : "Terjadi Kesalahan";

                if (e.msg) {
                    if (e.code === "EMAIL_NOT_FOUND" || e.code === "PASSWORD_MISMATCH") {
                        msg = isEN ? 'Incorrect email or password. Please try again' : "Periksa kembali email dan kata sandi Anda"
                    }
                }

                setError(msg);

                console.log(e)
            }
        } else {

            let userModel = new User();

            if (registerCred.password !== registerCred.confirmPassword) {
                setRegisterState({
                    ...defaultRegisterState,
                    passwordMismatch: true
                });

                setError(isEN ? 'Your password and confirmation password do not match' : "Kata sandi tidak sesuai");

                return;
            }

            if (!registerCred.email) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your Email' : "Harap isi alamat Email Anda");

                return;
            }

            if (registerCred.email !== registerCred.emailConfirmation) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Email Mismatch' : "Konfirmasi Pos-el tidak sesuai");

                return;
            }

            if (!registerCred.password) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your password' : "Harap lengkapi password");

                return;
            }

            if (!registerCred.confirmPassword) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your password confirmation' : "Harap isi konfirmasi password");

                return;
            }

            if (!registerCred.date_of_birth) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your Date of Birth' : "Harap isi tanggal lahir Anda.");

                return;
            }

            if (!registerCred.gender) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your gender' : "Harap isi jenis kelamin Anda");

                return;
            }

            if (!registerCred.phone_num) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                // setError(isEN ? 'Please fill in your Email' : "Harap isi alamat Email anda");

                // return;
            }

            if (!registerCred.full_name) {
                setRegisterState({
                    ...defaultRegisterState,
                });

                setError(isEN ? 'Please fill in your full name' : "Harap isi nama lengkap Anda");

                return;
            }

            /*
            if (!registerCred.email || !registerCred.password || !registerCred.confirmPassword ||
                !registerCred.date_of_birth || !registerCred.gender || !registerCred.phone_num || !registerCred.full_name) {

                setError(isEN ? 'Please fill all available fields' : "Harap lengkapi semua data");

                return;
            } else {
                setError(null)

                console.log("semua keisi")
            }
            */

            // if(isSafari){
            //
            //
            //     if(isNaN(new Date(registerCred.date_of_birth))){
            //
            //     }
            //
            // }

            try {
                let result = await userModel.register(registerCred)
                alert(isEN ? 'Registration Successful!' : "Registrasi Berhasil!")

                props.onHide(result.token)
                if (props.customOnHide) props.customOnHide();
                setLoginMode(true)

                console.log(result)
            } catch (e) {

                let msg = isEN ? 'An error occured' : "Terjadi kesalahan";

                console.log(msg);

                if (e.msg) {
                    if (e.code === "DUPLICATE_ACCOUNT") {
                        msg = isEN ? 'Email has been registered' : "Email telah terdaftar"
                    }

                    setRegisterState({
                        ...defaultRegisterState,
                        emailIsRegistered: e.code === "DUPLICATE_ACCOUNT"
                    })

                    setError(msg)

                    return;
                }

                setError(msg);

                console.log(e)
            }

        }


    }

    const renderLoginBody = () => {
        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {isEN ? 'Welcome to patjarmerah' : 'Selamat Datang di patjarmerah'}<br/>
        </p>
            <Form style={{
                fontSize: "0.7em"
            }}>
                <Form.Group>
                    <Form.Label>{isEN ? "E-mail":  "Akun patjarmerah (E-mail)"} </Form.Label>
                    <Form.Control
                        value={loginCred.email}
                        onChange={evt => {
                            setLoginCred({...loginCred, email: evt.target.value})
                            setRegisterCred({...registerCred, email: evt.target.value})
                        }}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Password' : 'Kata Sandi'}</Form.Label>
                    <Form.Control
                        value={loginCred.password}
                        onChange={evt => {
                            setLoginCred({...loginCred, password: evt.target.value})
                            setRegisterCred({...registerCred, password: evt.target.value})
                        }}
                        onKeyPress={event => {
                            if (event.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
            </Form>

            <p style={{
                fontSize: "0.7em"
            }}>{isEN ? 'Don\'t have an account?' : 'Belum punya akun?'} <a onClick={() => setLoginMode(false)}
                                                                           href={"#"}>{isEN ? 'Create an account' : 'Buat akun'}</a>
            </p>

        </>
    }

    const renderRegisterBody = () => {
        return <> <p style={{
            fontSize: "0.8em"
        }}>
            {isEN ? 'Welcome to patjarmerah' : 'Selamat Datang di patjarmerah'}<br/>
        </p>
            <Form
                style={{
                    fontSize: "0.7em"
                }}>
                <Form.Group>
                    <Form.Label>{isEN ? "E-mail":  "Pos-el (E-mail)"} <a
                        style={{color: "red", display: registerState.emailIsRegistered ? "inline" : "none"}}>(Email
                        Telah Terdaftar)</a></Form.Label>
                    <Form.Control
                        onChange={evt => {
                            setRegisterCred({...registerCred, email: evt.target.value})
                            setLoginCred({...loginCred, email: evt.target.value})
                        }}
                        style={{fontSize: "1em"}} type="email" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        {isEN ? "E-mail Confirmation":  "Konfirmasi Pos-el (E-mail)"}
                        <a style={{color: "red", display: registerState.confirmationEmailMismatch ? "inline" : "none"}}>Pos-el tidak sama</a>
                    </Form.Label>
                    <Form.Control
                        value={registerCred.emailConfirmation ? registerCred.emailConfirmation : ""}
                        onChange={evt => {
                            setRegisterCred({...registerCred, emailConfirmation: evt.target.value})
                            // setLoginCred({...loginCred, emailConfirmation: evt.target.value})
                        }}
                        autocomplete="new-password"
                        style={{fontSize: "1em"}} placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Password' : 'Kata Sandi'}</Form.Label>
                    <Form.Control
                        onChange={evt => {
                            setRegisterCred({...registerCred, password: evt.target.value})
                            setLoginCred({...loginCred, password: evt.target.value})
                        }}
                        autocomplete="new-password"
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Password Confirmation' : 'Konfirmasi Kata Sandi'}<a
                        style={{color: "red", display: registerState.passwordMismatch ? "inline" : "none"}}>(Password
                        Tidak Sama)</a></Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, confirmPassword: evt.target.value})}
                        style={{fontSize: "1em"}} type="password" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Date of Birth' : 'Tanggal Lahir'} {isDeviceSafari ? "(yyyy-mm-dd)" : ""}</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, date_of_birth: evt.target.value})}
                        value={registerCred.date_of_birth}
                        style={{fontSize: "1em"}} type="date" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{isEN ? 'Full Name' : 'Nama Lengkap'}</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, full_name: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>{isEN ? 'Phone Number' : 'Nomor Telepon'}</Form.Label>
                    <Form.Control
                        onChange={evt => setRegisterCred({...registerCred, phone_num: evt.target.value})}
                        style={{fontSize: "1em"}} type="text" placeholder=""/>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{isEN ? 'Gender' : 'Jenis Kelamin'}</Form.Label>
                    <br/>
                    <Form.Check
                        inline
                        onChange={evt => setRegisterCred({...registerCred, gender: evt.target.value})}
                        name={"gender"}
                        type={"radio"}
                        label={isEN ? 'Male' : `Pria`}
                        value={"M"}
                    />

                    <Form.Check
                        inline
                        onChange={evt => setRegisterCred({...registerCred, gender: evt.target.value})}
                        name={"gender"}
                        type={"radio"}
                        label={isEN ? 'Female' : `Wanita`}
                        value={"F"}
                    />
                </Form.Group>
            </Form>
            <p style={{
                fontSize: "0.7em"
            }}>{isEN ? 'Already have an account? ' : 'Sudah punya akun? '}
                <a onClick={() => {
                    setLoginMode(true)

                }} href={"#"}>{isEN ? 'Login' : 'Masuk'}</a>
            </p>
        </>
    }

    return <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={() => {
            if (props.customOnHide) props.customOnHide()
            setError(null);
            setRegisterState({...registerState, passwordMismatch: false, emailIsRegistered: false})

            props.onHide();
        }}
    >
        <Modal.Header>
            {/*<Modal.Title>*/}
            <Container>
                <Row>
                    <Col md={6} xs={6} style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            style={{
                                height: "4vh",
                                objectFit: "contain",
                            }}
                            src={GlobalData.subfolder + "image/logo/patjarmerah.png"}/>
                    </Col>
                    <Col md={6} xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        {props.customOnHide ? <p style={{fontWeight: 'bold', cursor: 'pointer', color: 'grey'}}
                                                 onClick={props.customOnHide}>x</p> : null}
                    </Col>
                </Row>
            </Container>


            {/*</Modal.Title>*/}
        </Modal.Header>
        <Modal.Body>
            {loginMode ? renderLoginBody() : renderRegisterBody()}
            <span style={{
                color: "red",
                fontSize: "0.8em"
            }}>
                    {error ? error : props.message}
                </span>

        </Modal.Body>
        <Modal.Footer>
            <Container>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <Button
                        style={{backgroundColor: Palette.MAROON, borderWidth: 0, width: '100%'}}
                        onClick={handleSubmit}>
                        {loginMode && isEN ? 'Login' : loginMode && !isEN ? "Masuk" : !loginMode && isEN ? "Register" : 'Daftar'}
                    </Button>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 10}}>
                    {loginMode ? <p style={{
                        fontSize: "0.7em"
                    }}>
                        <a href="#"
                           onClick={props.showForgotPassword}>{isEN ? 'Forgot your password?' : 'Lupa kata sandi?'}</a>
                    </p> : null}
                </Row>
            </Container>
        </Modal.Footer>
    </Modal>
}
