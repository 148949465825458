import PaymentConfirmationModal from "./PaymentConfirmationModal";
import PaymentGuideModal from "./PaymentGuideModal";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import textFormatter from "../../util/textFormatter";

export default function BookModal(props) {
    const [referenceHeight, setReferenceHeight] = useState(0);
    let {book} = props;
    book = book ? book : {additional_information: {}};

    useEffect(() => {
        const refComponent = document.getElementById('reference-component');
        if (refComponent) setReferenceHeight(refComponent.height)
    },[])

    return <>
        <Modal
            {...props}
            size="lg"
            centered
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <img
                            onLoad={()=>{
                                console.log("setting ref")
                                const refComponent = document.getElementById('reference-component');
                                if (refComponent) setReferenceHeight(refComponent.height)
                            }}
                            id={"reference-component"}
                            style={{
                                width: "100%",
                                objectFit: "cover"
                            }}
                            src={book.additional_information.image}/>
                    </Col>
                    <Col md={6}>
                        <div style={{
                            height: referenceHeight ? referenceHeight : 100,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            <div style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                                overflowY: "scroll",
                                height: '100%',
                            }}>

                                <div style={{
                                    fontSize: "1em",
                                    marginBottom: '10px',
                                }}>{book.title}</div>

                                <span style={{fontSize: '0.75em', fontWeight: 'bold'}}>Harga</span>
                                <div style={{
                                    fontSize: "1em",
                                    marginBottom: '10px'
                                }}>
                                    Rp{textFormatter.moneyFormatter(book.additional_information.price)}
                                </div>


                                {book.additional_information.author ?
                                    <>
                                        <span style={{fontSize: '0.75em', fontWeight: 'bold'}}>Penulis</span>
                                        <div style={{
                                            fontSize: "1em",
                                            marginBottom: '10px'
                                        }}>{book.additional_information.author}</div>
                                    </>

                                    : null}

                                <span style={{fontSize: '0.75em', fontWeight: 'bold'}}>Penerbit</span>
                                <div style={{
                                    fontSize: "1em",
                                    marginBottom: '10px'
                                }}>
                                    {book.additional_information.publisher}
                                </div>

                                {
                                    book?.additional_information.summary ?
                                        <>
                                            <span style={{fontSize: '0.75em', fontWeight: 'bold'}}>Synopsis</span>
                                            <div style={{
                                                fontSize: "1em",
                                                marginBottom: '10px',
                                                whiteSpace : "pre-line"
                                            }}>
                                                {book.additional_information.summary}
                                            </div>
                                        </> :
                                        null
                                }

                            </div>

                            <a href={book.additional_information.url} target={'_blank'} style={{textDecoration: 'none'}}>
                                <Button
                                    onClick={() => {

                                    }}
                                    size={"sm"} block variant={"outline-danger"}>
                                    Beli
                                </Button>
                            </a>
                        </div>

                    </Col>

                </Row>
            </Modal.Body>

        </Modal>
    </>
}
