import {Button, Col, Container, Modal, Row, Spinner} from "react-bootstrap";
import React, {Component, useState} from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import moment from "moment";
import 'moment/locale/id'
import Session from "../../models/Session";
import Dropzone from "react-dropzone";
import {FaFileUpload} from "react-icons/fa";
import {
    isMobile
} from "react-device-detect";

export default function PaymentConfirmationModal(props) {

    let [imageUrl, setImageUrl] = useState(null)
    let [isLoading, setLoading] = useState(false)
    let [errorMessage, setErrorMessage] = useState("")

    const onImagePicked = async function (image) {
        setLoading(true)
        setErrorMessage("")
        try {
            let result = await new Session().uploadPaymentProof(props.session.id, image[0])
            setLoading(false)
            console.log(result)
            setImageUrl(result.location)
        } catch (e) {


            let tempMessage = "Kesalahan Terjadi"

            if(e.msg){
                if(e.msg.message){
                    tempMessage = e.msg.message
                }
            }

            setLoading(false)
            setErrorMessage(tempMessage)

            console.log(e)

        }
    }

    const submitForm = async function () {
        if(props.session.status === 'REJECTED') {
            try {
                const response = await  new Session().cancel(props.session.id);

                if (response.success) {
                    try {
                        let result = await new Session().confirmTransaction(props.session.id, {payment_proof_url: imageUrl})
                        props.fetchSessions();
                        props.onClose(true)
                    } catch (e) {
                        console.log(e)
                        alert("Terjadi Kesalahan, coba upload ulang bukti anda. Jika ini berulang harap kontak kami.")
                    }
                }
            } catch (e) {
                console.log(e)
            }
        } else if (props.session.registration_info && props.session.registration_info.payment_proof_url) {
            try {
                let result = await new Session().updateTransactionConfirmation(props.session.id, {payment_proof_url: imageUrl})
                props.fetchSessions();
                props.onClose(true)
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                let result = await new Session().confirmTransaction(props.session.id, {payment_proof_url: imageUrl})
                props.fetchSessions();
                props.onClose(true)
            } catch (e) {
                console.log(e)
            }
        }

        // try {
        //     let result = await new Session().confirmPayment(props.session.id, imageUrl)
        //     console.log(result)
        //     props.onClose(true)
        // } catch (e) {
        //     console.log(e)
        // }
    }

    return <Modal
        size="md"
        centered
        show={props.show}
        onHide={() => props.onClose()}
    >
        <Modal.Header closeButton>
            Konfirmasi Pembelian
        </Modal.Header>

        <Container>
            <Row>
                {imageUrl || (props.session.registration_info && props.session.registration_info.payment_proof_url)?
                    <div style={{
                        flex: 1,
                        height: "10em",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: "center",
                        borderWidth: 2,
                        borderRadius: 2,
                        margin: 10,
                        borderColor: '#eeeeee',
                        borderStyle: 'dashed',
                        backgroundColor: '#fafafa',
                        color: '#bdbdbd',
                        outline: 'none',
                        fontSize: "1.5em",
                        transition: 'border .24s ease-in-out'
                    }}>
                        <img
                            style={{
                                width: "100%",
                                height: "10em",
                                objectFit: "cover"
                            }}
                            src={imageUrl ? imageUrl : props.session.registration_info && props.session.registration_info.payment_proof_url ? props.session.registration_info.payment_proof_url : null}
                        />
                    </div>
                    : null}

            </Row>
            <Row>

                <Dropzone onDrop={acceptedFiles => onImagePicked(acceptedFiles)}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} style={{
                            flex: 1,
                            height: "10em",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: "center",
                            borderWidth: 2,
                            borderRadius: 2,
                            margin: 10,
                            borderColor: '#eeeeee',
                            borderStyle: 'dashed',
                            backgroundColor: '#fafafa',
                            color: '#bdbdbd',
                            outline: 'none',
                            fontSize: "1.5em",
                            transition: 'border .24s ease-in-out'
                        }}>
                            <FaFileUpload style={{
                                fontSize: "3em",
                                marginBottom: "0.2em"
                            }}/>
                            <div>
                                Unggah Bukti Pembayaran
                            </div>
                            <div style={{
                                fontSize: "0.7em",
                                textAlign : "center"
                            }}>
                                {isMobile ? "Klik Kotak ini untuk mengunggah"
                                    : "Klik kotak atau tarik file kedalam kotak untuk mengunggah" }
                            </div>
                            <div style={{
                                fontSize: "0.7em",
                                textAlign : "center",
                                color:"red"
                            }}>
                                {errorMessage}
                            </div>
                            <Spinner
                                style={{fontSize: "1em", display: isLoading ? "inline" : "none"}} animation="border"/>
                            <input {...getInputProps()} />
                        </div>
                    )}
                </Dropzone>
            </Row>
            <Row style={{
                padding: '10px',
                paddingTop: '2px'
            }}>
                <Button
                    disabled={!imageUrl}
                    size={"sm"} block variant={imageUrl ? "danger" : "secondary"}
                    onClick={() => submitForm()}>
                    Kirim
                </Button>
            </Row>
        </Container>
    </Modal>

}
