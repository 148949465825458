import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button, Modal} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import PaymentGuideModal from "../reusable/PaymentGuideModal";

export default function ZodiacPromotion(props) {
    const bookCoverRef = useRef(null);
    const [isModalVisible, setModalVisible] = useState(false)
    const [sessionModalVisible, setSessionModalVisible] = useState(false)

    useEffect(() => {
        // getCampaign();
        ApplicationActivities.sendActivity({
            type: "ZODIAC",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "mobile-website" : "website"
        })
    }, [])

    let alexSession = {
        type: "book",
        additional_information : JSON.stringify({
            type: "book",
            "books": [
                {
                    "name": "Paket A - Sesi Rabu 29 April 2020",
                    "url": "https://www.tokopedia.com/republikfiksi/paket-a-rabu-let-me-tell-you-a-story-about-zodiac"
                },
                {
                    "name": "Paket A - Sesi Sabtu 2 Mei 2020",
                    "url": "https://www.tokopedia.com/republikfiksi/paket-a-sabtu-let-me-tell-you-a-story-about-zodiac"
                }
            ]
        })
    }

    return (
        <PageWrapper
            headerActive={'zodiac-promotion'}
        >
            <Container fluid style={{backgroundColor: '#ededed', paddingBottom: 15}}>
                <Container fluid style={{paddingTop: '1em'}}>
                    <Row style={{paddingTop: '2.5em'}}>
                        <Col xl={2}/>
                        <Col xs={12} xl={4} md={6} style={{display: 'flex', justifyContent: 'center'}}>
                            <img ref={bookCoverRef}
                                 src={require("../../asset/zodiac-book-cover.jpg")}
                                 style={{
                                     maxHeight: "80vh",
                                     width: '100%', borderRadius: 10, objectFit: 'contain'
                                 }}/>
                        </Col>

                        <Col sm={12} xl={4} md={6} style={{
                            paddingLeft: '2em',
                            paddingRight: '2em',
                            paddingTop: '0.5em',
                            color: '#2a2d35',
                            textAlign: 'center',
                            fontSize: '1em',
                        }}>
                            <div style={{
                                fontSize: '1em',
                                fontWeight: '300',
                            }}>Pre-order karya terbaru Alexander Thian
                            </div>
                            <div style={{fontSize: '1em', fontWeight: 'bold'}}>#LetMeTellYouAStory about</div>
                            <div style={{fontSize: '2.1em', fontWeight: 'bold'}}>ZODIAC</div>

                            <div style={{textAlign: "left"}}>

                                <br/>
                                Siapa orang bodoh yang melamar jadi penulis skenario padahal tidak tahu apa itu
                                skenario? Aku, lah!
                                <br/><br/>
                                Siapa yang kepedean menjadi penulis tanpa latar belakang pengetahuan soal menulis? Siapa
                                lagi kalau bukan aku?
                                <br/><br/>
                                Aku bahkan tak ragu menutup konterku ketika diajak seorang teman melamar jadi penulis
                                skenario. Padahal, apa itu skenario saja aku tidak tahu. Mimpi jadi penulis? Boro-boro!
                                <br/><br/>
                                Meski demikian, aku bertahan cukup lama belajar menulis dari dasar sambil menghadapi bos
                                yang kalau ngomong kayak orang kumur-kumur.
                                <br/><br/>
                                Satu ilmu penting yang kupelajari selama bertahun-tahun menjadi penulis skenario adalah
                                belajar mengenal karakter manusia melalui zodiaknya.
                                <br/><br/>
                                Kok, bisa? Apa hubungannya menulis cerita dengan zodiak?

                            </div>

                            <div style={{
                                textAlign: "left",
                                fontSize: "0.9em",
                                marginTop : "0.2em"
                            }}>
                                <b>Informasi Buku : </b>
                                <br/>

                                <br/>
                                Halaman : +/- 200 Halaman BW/FC
                                <br/>
                                Ukuran : 14 x 20 cm
                                <br/>
                                <br/>
                                Buku akan dikirimkan pada akhir Mei 2020
                            </div>


                        </Col>

                        <Col xl={2}/>
                    </Row>

                    <Row>
                        <Col xl={2} md={1}/>
                        <Col xs={12} xl={4} md={5} style={{marginTop: 30}}>
                            <Row>
                                <div style={{
                                    position : "absolute",
                                    width : "96%",
                                    paddingTop : "96%",
                                    borderRadius: 10,
                                    marginLeft: '2%',
                                    backgroundColor:"rgba(0,0,0,0.5)"
                                }}/>
                                <div style={{
                                    position : "absolute",
                                    margin : "auto",
                                    fontSize : "3em",
                                    color : "white",
                                    textAlign :"center",
                                    paddingTop : "40%",
                                    height : "100%",
                                    width : "96%",
                                    marginLeft: '2%'
                                }}>
                                    <b style={{
                                        top : "50%"
                                    }}>
                                        Tidak Tersedia
                                    </b>
                                </div>
                                <img ref={bookCoverRef}
                                     src={'image/paket-a-description.jpeg'}
                                     style={{width: '96%', height: '96%', borderRadius: 10, objectFit: 'contain', marginLeft: '2%'}}/>

                            </Row>
                        </Col>

                        <Col xs={12} xl={4} md={5} style={{marginTop: 30}}>
                            <Row>
                                <img ref={bookCoverRef}
                                     src={'image/paket-b-description.jpeg'}
                                     style={{width: '96%', borderRadius: 10, objectFit: 'contain', marginLeft: '2%'}}/>
                            </Row>
                            <Row>
                                <Button variant={"outline-primary"} block
                                        style={{width: '90%', marginLeft: '5%', marginTop: 15}}
                                        onClick={() => window.open('https://www.tokopedia.com/republikfiksi/paket-b-let-me-tell-you-a-story-about-zodiac', "_blank")}>Beli</Button>
                            </Row>
                        </Col>
                        <Col xl={2} md={1}/>
                    </Row>
                </Container>
            </Container>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={isModalVisible}
            >
                <ModalHeader>
                    <Container fluid>
                        <Row style={{fontWeight: 400}}>
                            <Col xs={8} md={10}>
                                Tanggal Ngobrol Virtual
                            </Col>
                            <Col xs={4} md={2} style={{
                                fontWeight: 'bold',
                                color: 'grey',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                cursor: 'pointer',
                            }} onClick={() => setModalVisible(false)}>
                                X
                            </Col>
                        </Row>
                    </Container>
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col>
                            <Button variant={"outline-primary"} block
                                    style={{width: '90%', marginLeft: '5%'}}
                                    onClick={() => window.open('https://www.tokopedia.com/republikfiksi/paket-a-rabu-let-me-tell-you-a-story-about-zodiac', '_blank')}>Rabu,
                                29 April 2020</Button>

                            <Button variant={"outline-primary"} block
                                    style={{width: '90%', marginLeft: '5%', marginTop: 5}}
                                    onClick={() => window.open('https://www.tokopedia.com/republikfiksi/paket-a-sabtu-let-me-tell-you-a-story-about-zodiac', '_blank')}>Sabtu,
                                2 Mei 2020</Button>

                        </Col>
                    </Row>
                </ModalBody>

            </Modal>

            <PaymentGuideModal
                onClose={() => {
                    setSessionModalVisible(false)
                }}
                show={sessionModalVisible}
                session={alexSession}
            />

        </PageWrapper>
    )
}
