import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";

export default function PapermoonPromotion(props) {
    const [isLoginFormShown, showLoginForm] = useState(false);
    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [papermoonSesssions, setPapermoonSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    useEffect(() => {
        new Session().getPapermoonSessions().then(res => {
            console.log(res)
            setPapermoonSessions(res);
        }).catch(err => console.log(err))

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

        // ApplicationActivities.sendActivity({
        //     type: "PAPERMOON",
        //     description: "",
        //     timestamp: new Date(),
        //     os: isMobile ? "mobile-website" : "website"
        // })
    }, []);

    return (
        <PageWrapper
            loginShow={isLoginFormShown}
            customOnHide={isLoginFormShown ? () => showLoginForm(false) : null}
            headerActive={'papermoon-promotion'}
            hideFooter
        >
            <Container fluid>
                <Container style={{paddingTop: '1em', marginBottom: 70}}>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: 25}}>
                        <img src={require("../../asset/new-papermoon/welcome screen22.jpg")}
                             style={{width: '100%', maxWidth: 900, objectFit:'contain', maxHeight : 500}}/>
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: 900,
                            background: `url('/image/beetle-down.png')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 5%",
                        }}>

                            {/*<p style={{*/}
                            {/*    fontSize: '1.3em',*/}
                            {/*    fontWeight: 'bolder'*/}
                            {/*}}>{isEN ? 'DESCRIPTION' : 'DESKRIPSI'}</p>*/}

                            <div>
                                {
                                    isEN ?
                                        <p>
                                            As part of discussions about “Puppetry and Ecology in Indonesia”,<br/>
                                            Papermoon Puppet Theatre and patjarmerah present
                                        </p>
                                        :
                                        <p>
                                            Sebagai bagian dari rangkaian diskusi  “Puppetry and Ecology in Indonesia”,<br/>
                                            Papermoon Puppet Theatre bekerjasama dengan patjarmerah, mempersembahkan
                                        </p>
                                }

                            </div>

                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>{isEN ? 'TITLE' : 'JUDUL'}</p>

                                <div style={{marginTop: -5,}}>
                                    A BUCKET OF BEETLES
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'CREATED AND PERFORMED BY' : 'KARYA'}</p>

                                <div style={{marginTop: -5}}>
                                    <a href={"https://www.instagram.com/papermoonpuppet/"} target={'_blank'}>Papermoon
                                        Puppet Theatre</a>
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'DURATION' : 'DURASI'}</p>

                                <div style={{marginTop: -5}}>
                                    {isEN ? '1.5 hours' : '1,5 jam'}<br/>
                                    {isEN ?
                                        'Including performance, live backstage tour, and QnA session with Papermoon Puppet Theatre (INA),  and  will be moderated by Marianna Lis ( PhD and researcher from Poland)' :
                                        'Termasuk pertunjukan, tur belakang panggung, dan sesi bincang dengan tim Papermoon Puppet Theatre seusai pementasan yang akan dimoderasi oleh Marianna Lis (peneliti dari Polandia)'}
                                </div>

                            </div>

                            <div
                                style={{
                                    // marginTop: 15,
                                    marginBottom: 35,
                                    // paddingBottom: 15,
                                    // paddingTop: 15,
                                    textAlign: 'left',
                                    background: `url('/image/kumbang.png')`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "80px 80px",
                                    backgroundPosition: dimensions.width > 800 ? "40% 50%" : "60% 50%",
                                }}>

                                <div style={{
                                    marginBottom: 35, textAlign: 'left',

                                }}>

                                    <p style={{
                                        fontSize: '1.1em',
                                        fontWeight: 'bolder',
                                        textAlign: 'left'
                                    }}>{isEN ? 'DAY/DATE' : 'HARI/TANGGAL'}</p>

                                    <div style={{marginTop: -5}}>
                                        {isEN ? 'Sunday, October 25 2020' : 'Minggu, 25 Oktober 2020'}
                                    </div>

                                </div>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'LOCATION' : 'LOKASI'}</p>

                                <div style={{marginTop: -5}}>
                                    {isEN ? 'Online - Google Meet' : 'Daring - Google Meet'}
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'TICKET' : 'TIKET'}</p>

                                <div style={{marginTop: -5}}>
                                    {isEN ? "- USD 10" : "- IDR 100K"}&nbsp;
                                    {isEN ? <span style={{color: 'red'}}>*</span> : null}
                                    {/*<br/>*/}
                                    {/*<span>- Barter {isEN ? "(Terms and Condition Applied)" : "(Syarat dan Ketentuan Berlaku)"}</span>*/}

                                    {/*<span*/}
                                    {/*style={{fontSize: "0.9em"}}>{isEN ? "Terms and Condition Applied" : "Syarat dan Ketentuan Berlaku"}</span>*/}
                                </div>

                                {isEN ?
                                    <div style={{fontSize: 12, color: 'grey', marginTop: 10}}>
                                        <span style={{color: 'red', marginRight: 5}}>*</span>
                                        The price will be converted into rupiah with an exchange rate of 1 USD = IDR
                                        15,000
                                    </div> : null
                                }

                            </div>

                            <div>

                                {/*Papermoon Puppet Theatre<br/>*/}
                                {/*{isEN ? 'Presents' : 'Mempersembahkan'}<br/><br/>*/}

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>
                                    {isEN ? "SYNOPSIS" : "SINOPSIS"}
                                </p>

                                {isEN ? 'A non-verbal cinematic puppet performance' : 'Pertunjukan sinema teater boneka tanpa kata-kata'}<br/>

                                {isEN ? "“A Bucket of Beetles” is a story about friendship and the relationship between human beings and nature."
                                    : "“A Bucket of Beetles” adalah kisah mengenai persahabatan , dan hubungan antara makhluk hidup.\n"}<br/><br/>

                                ---<br/><br/>

                                {
                                    isEN ?
                                        "Wehea is a little boy who lives in a big rain forest. Just like any other people who live there, Wehea has a special connection with nature. Even the smallest beings in the forest are his friends. " :
                                        "Wehea adalah seorang anak lelaki yang tinggal di tengah hutan hujan tropis. Sebagaimana masyarakat hutan lainnya, Wehea memiliki hubungan istimewa dengan alamnya. Bahkan makhluk terkecil di hutan itu menjadi temannya."
                                }

                                <br/><br/>

                                {
                                    isEN ?
                                        "One day, Wehea sees a very special beetle. He then embarks on an adventure to meet the beetle; the very special rhinoceros beetle who always searches for the lights and turns out to save his life."
                                        :
                                        "Hingga suatu hari, Wehea melihat seekor kumbang yang sangat istimewa. Wehea pun menempuh perjalanan menantang demi berjumpa dengan si kumbang istimewa tersebut; seekor kumbang badak pencari cahaya yang menjadi penyelamat hidupnya."
                                }

                                <br/>

                                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <img src={'/image/beetle.jpg'} style={{width: 40, height: 40}}/>
                                </Row>

                                {isEN ?
                                    "“A Bucket of Beetles” is more than just a story of a beautiful friendship. . It is a story about the connection between humans and nature.  A story that leaves us questioning ourselves: are we taking care of our water, soil, and air enough?" :
                                    "“A Bucket of Beetles” adalah cerita tentang persahabatan. Namun, lebih daripada itu, ia juga bercerita tentang keterhubungan manusia dengan alam.  Cerita yang menghadapkan kepada pertanyaan: apakah kita cukup merawat air, tanah, dan udara?"
                                }
                                <br/>
                                <br/>
                                ---
                                <br/>
                                <br/>
                                {isEN ?
                                    "This play is based on a story told by a 5-years old little boy." :
                                    "Pementasan berdasarkan sebuah cerita yang dikisah oleh seorang anak lelaki berusia 5 tahun. Semua desain boneka berkarakter hewan dalam pementasan ini diambil dari gambar yang juga dibuatnya."
                                }

                                <br/>
                                {
                                    isEN?
                                        "All designs of the animal puppets in the play are taken from his drawings."
                                        :
                                        ""
                                }

                            </div>
                            {/*{isEN ? "Is Supported By patjarmerah, Kotasis, dan Intellivent" : "Didukung oleh patjarmerah, Kotasis, dan Intellivent"}*/}

                            <div style={{marginTop: 30, marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'SUPPORTED' : 'DIDUKUNG'}</p>

                                <div style={{marginTop: -5}}>
                                    {
                                        isEN ?
                                            <span>
                                                <a href={'https://www.patjarmerah.com/'}
                                                   target={'_blank'}>patjarmerah</a>,  <a
                                                href={'https://www.kotasis.com/'}
                                                target={'_blank'}>/KOTASIS/</a>, and <a
                                                href={'https://www.instagram.com/intelliventid/'}
                                                target={'_blank'}>Intellivent</a>
                                            </span> :
                                            <span>
                                                <a href={'https://www.patjarmerah.com/'}
                                                   target={'_blank'}>patjarmerah</a>, <a
                                                href={'https://www.kotasis.com/'}
                                                target={'_blank'}>/KOTASIS/</a>, dan <a
                                                href={'https://www.instagram.com/intelliventid/'}
                                                target={'_blank'}>Intellivent</a>
                                            </span>
                                    }
                                    {/*{isEN ? 'patjarmerah, /KOTASIS/, and Intellivent' : 'patjarmerah, /KOTASIS/, dan Intellivent'}*/}
                                    {/*{isEN ? <a href="https://www.instagram.com/patjarmerah_id/">patjarmerah</a>, /KOTASIS/, and <a href="https://www.instagram.com/intelliventid/">Intellivent</a>' : '<a href="https://www.instagram.com/patjarmerah_id/">patjarmerah</a>, /KOTASIS/, dan <a href="https://www.instagram.com/intelliventid/">Intellivent</a>'}*/}
                                </div>

                            </div>
                        </div>


                        <div style={{
                            backgroundColor: 'white',
                            padding: 30,
                            borderRadius: 4,
                            border: '1px solid #d1d1d1',
                        }} ref={buyTicketRef}>
                            <p style={{
                                fontSize: '1.1em',
                                fontWeight: 'bold'
                            }}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}</p>

                            <p>
                                <span href={"#"} onClick={() => {
                                    setIsToCModalOpen(true)
                                }} style={{
                                    color : "#007bff",
                                    cursor : "pointer"
                                }}>
                                    {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                                </span>
                            </p>

                            {
                                papermoonSesssions.map((session, idx) => {
                                    return <PapermoonTicketNode isEN={isEN} session={session}
                                                                lineSeparator={idx !== papermoonSesssions.length - 1}
                                                                showLoginForm={showLoginForm}/>
                                })
                            }


                            {
                                isEN ?
                                    <>
                                        <Row style={{
                                            height: 1,
                                            backgroundColor: '#e6e6e6',
                                            marginTop: 20,
                                            marginBottom: 20
                                        }}/>
                                        <div style={{
                                            width : "100%",
                                            display : "flex",
                                            flexDirection  :"row",
                                            alignItems : "flex-end",
                                            justifyContent : "flex-end"
                                        }}>
                                            <Button
                                                variant={"outline-primary"}
                                                onClick={()=>{
                                                    setIsPPModalOpen(true)
                                                }}
                                            >
                                                <div style={{
                                                    display : "flex",
                                                    flexDirection : "row",
                                                    alignItems : "center"
                                                }}>
                                                    <img src={require("../../asset/paypal-logo.png")} style={{height : "1em"}}/>
                                                    <div style={{marginLeft : "1em"}}>Pay With Paypal</div>
                                                </div>
                                            </Button>
                                        </div>

                                    </>
                                    :
                                    null
                            }



                        </div>

                    </Row>
                    {
                        isMobile ?
                            <Row>
                                <div>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </Row>
                            :
                            null
                    }


                </Container>

                <Modal
                    size="md"
                    onHide={() => {
                        setIsToCModalOpen(false)
                    }}
                    show={isToCModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                    </Modal.Header>
                    <Modal.Body>
                        <ul>
                            <li>
                                {isEN ? "All Sales are Final." : "Transaksi yang dilakukan bersifat final."}
                            </li>
                            <li>
                                {isEN ? "Items that have been purchased cannot be returned." : "Tiket yang dibeli tidak bisa ditukar atau dikembalikan."}
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ul>
                    </Modal.Body>

                </Modal>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsPPModalOpen(false)
                    }}
                    show={isPPModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Pay With Paypal" : "Pembayaran Menggunakan Paypal"}
                    </Modal.Header>

                    <Modal.Body>
                        As an alternative method to accomodate overseas international payment, we now accept payment using Paypal.<br/>

                        <br/>
                        <br/>

                        <b>How to Confirm Your Paypal Payment</b>
                        <ol>
                            <li>
                                Create an account in patjarmerah.com
                            </li>
                            <li>
                                Pay with Paypal using the following <a href={"https://www.paypal.com/paypalme/papermoonpuppet"} target={"_blank"}>link</a>
                            </li>
                            <li>
                                Confirm your payment via email to <a href = "mailto: renjana@patjarmerah.com">renjana@patjarmerah.com</a>. In the email, please include your <b>payment proof</b>, registered <b>email</b>, and your <b>preferred show time</b>. Please also use the subject <b>Paypal Payment Confirmation</b>.
                            </li>
                            <li>
                                Please wait for our admins to process your payment and we will get back to you.
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ol>

                        <br/>

                        <b>Disclaimer</b>

                        <ol>
                            <li>
                                Please be aware that payment using paypal is confirmed manually by our admins, so it may takes several hours before confirmation.
                            </li>
                            <li>
                                If your preferred show time is full, we will put you in another time.
                            </li>
                            <li>
                                We recommend you to pay using other method first before using paypal.
                            </li>
                        </ol>
                    </Modal.Body>

                </Modal>

                {
                    !isMobile ?
                        <Row style={{
                            position: 'fixed',
                            bottom: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            borderTop: '1px solid #e6e6e6',
                            paddingTop: 25,
                            paddingBottom: 30,
                            paddingRight: 20,

                        }}
                        >
                            <Col style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                flexDirection: 'row'
                            }}>

                                <div style={{
                                    marginTop: -5,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    height: "100%",
                                    marginRight: 10
                                }}>

                                    <img
                                        src={'/image/kumbang.png'}
                                        style={{
                                            width: 60, height: 60,
                                            transform: 'rotate(97deg)'
                                        }}/>

                                </div>

                                {/*<div style={{*/}
                                {/*    marginTop: -5,*/}
                                {/*    display: "flex",*/}
                                {/*    flexDirection: "column",*/}
                                {/*    justifyContent: "center",*/}
                                {/*    height: "100%",*/}
                                {/*    fontWeight : "bold",*/}
                                {/*    marginRight : 15*/}
                                {/*}}>*/}
                                {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ?*/}
                                {/*    <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                                {/*</div>*/}


                                <Button style={{
                                    fontWeight: 'bolder',
                                    fontSize: '1.05em',
                                    // backgroundColor: '#cf484f',
                                    // borderWidth: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    margin: 3,
                                    width: 150,
                                }}
                                        onClick={() => buyTicketRef.current.scrollIntoView({behavior: 'smooth'})}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}
                                </Button>
                                {/*<Button style={{*/}
                                {/*    fontWeight: 'bolder',*/}
                                {/*    fontSize: '1.05em',*/}
                                {/*    width: 150,*/}
                                {/*    paddingTop: 10,*/}
                                {/*    paddingBottom: 10,*/}
                                {/*    margin: 3*/}
                                {/*}} onClick={() => window.open('https://forms.gle/WhtE6prUEbTaBE5w9')}*/}
                                {/*        variant={'outline-primary'}>*/}
                                {/*    BARTER*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                        :
                        <Row style={{
                            position: 'fixed',
                            bottom: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            borderTop: '1px solid #e6e6e6',
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingRight: 20,
                        }}>
                            <Col xs={2} style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                                <img
                                    src={'/image/kumbang.png'}
                                    style={{
                                        width: 60, height: 60,
                                        transform: 'rotate(97deg)'
                                    }}/>
                            </Col>
                            <Col xs={10}>
                                <Button
                                    style={{
                                        fontWeight: 'bolder',
                                        fontSize: '1.05em',
                                        // backgroundColor: '#cf484f',
                                        // borderWidth: 0,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        margin: 3,
                                        width: "100%"
                                    }}
                                    onClick={() => buyTicketRef.current.scrollIntoView({behavior: 'smooth'})}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}
                                </Button>
                                {/*<Button*/}
                                {/*    style={{*/}
                                {/*        fontWeight: 'bolder',*/}
                                {/*        fontSize: '1.05em',*/}
                                {/*        paddingTop: 10,*/}
                                {/*        paddingBottom: 10,*/}
                                {/*        margin: 3,*/}
                                {/*        width: "100%"*/}
                                {/*    }} onClick={() => window.open('https://forms.gle/WhtE6prUEbTaBE5w9')}*/}
                                {/*    variant={'outline-primary'}>*/}
                                {/*    BARTER*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                }


            </Container>
        </PageWrapper>
    )
}
