import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import moment from "moment";
import ScheduleNode from "../reusable/ScheduleNode";
import GlobalData from "../../util/GlobalData";
import _ from "lodash";
import SessionModal from "../reusable/SessionModal";
import {useHistory} from 'react-router-dom'

let firstLoad = false

export default function SurgaAnjingLiar(props) {

    const [isLoginFormShown, setIsLoginFormShown] = useState(false);
    const [loginMessage, setLoginMessage] = useState("")

    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [fbaSessions, setFbaSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const history = useHistory()

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    const [isSessionModalShow, setIsSessionModalShown] = useState(false)
    const [isPaymentModalShow, setIsPaymentModalShow] = useState(false)
    const [paymentUrl, setPaymentUrl] = useState("")
    const [selectedSession, setSelectedSession] = useState(null)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    const fetchSessions = async () => {

        console.log("is calling with " + GlobalData.token)

        let sessions = await new Session().getFBASessions();

        let defaultId = 0

        if (firstLoad) {
            if (props.match.params.id) {
                defaultId = this.props.match.params.id
                console.log("def", defaultId)
            }
            firstLoad = false
        }

        if (defaultId > 0) {
            let session = sessions.find(e => {
                console.log(defaultId, parseInt(e.id), parseInt(e.id) === parseInt(defaultId))
                return parseInt(e.id) === parseInt(defaultId)
            })
            console.log("selected session", sessions)
            if (session) {
                // this.setState({
                //     ...this.props,
                //     selectedSession: session,
                //     isSessionModalShown: true
                // })
            }

        }

        console.log(sessions)
        setFbaSessions(sessions)

    }

    useEffect(() => {

        fetchSessions()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

    }, []);

    const showLogin = () => {
        setIsLoginFormShown(true)
        setLoginMessage('Harap login untuk melanjutkan pendaftaran')
        setIsSessionModalShown(false)
    }

    return (
        <PageWrapper
            backgroundColor={"Grey"}
            loginShow={isLoginFormShown}
            message={loginMessage}
            customOnHide={isLoginFormShown ? () => setIsLoginFormShown(false) : null}
            headerActive={'surgaanjingliar'}
            hideFooter={true}
        >

            <Container fluid style={{
                backgroundImage: `url(${require("../../asset/surgaanjingliar/background-adimas.jpg")})`,
                backgroundBlendMode: "color",
            }}>
                <Row fluid style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",
                    backgroundBlendMode: "color",
                }}>
                    <img src={require("../../asset/surgaanjingliar/coveradimas.png")}
                             style={{
                                 width: '100%',
                                 maxWidth: 1140,
                                 height: "100%",
                                 backgroundBlendMode: "color",
                             }}/>
                </Row>
                <Row style={{}}>
                    <Container style={{
                        paddingTop: '1em',
                        paddingBottom: '1em',
                        backgroundColor: "#FFFFFFAA",
                    }}>
                        <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 25}}>
                            <Col xl={10}>
                                <p style={{
                                    fontFamily: "Inika",
                                    fontSize: 28,
                                    textAlign: "center",
                                    fontWeight: "bold"
                                }}>CEPAT BACA SEBELUM LAMAN INI DIHAPUS ADMIN!
                                </p>
                                <div style={{fontFamily: "Patrick Hand", fontSize: 30, fontWeight: "reguler"}}>
                                    <p style={{marginTop: 50}}>
                                        Setelah pos-el, website patjarmerah ini juga berhasil kubajak. Aku ingin
                                        menyampaikan bahwa aku baru saja menyelesaikan naskah <i>Surga Anjing Liar</i>.
                                        Karena bab-bab awal adalah pertaruhan bagi penulis, aku mau kamu ikut menilai
                                        bagus
                                        tidaknya tulisanku pada bagian pembukanya. Ini penting sekali buatku. Mari kita
                                        buat
                                        sejarah bersama.
                                    </p>
                                    <p style={{marginTop: 30}}>
                                        Silakan mendaftar ke acara ini. Aku akan mengirim bab 1 dan 2 dari naskahku
                                        kepadamu
                                        beberapa hari sebelum acara dimulai. Kamu boleh menyiapkan komentar dan
                                        unek-unekmu
                                        untuk disampaikan saat acara nanti. Bila perlu, cetak dan coret-coret saja bila
                                        kamu
                                        ingin membuat catatan.
                                    </p>
                                    <p style={{marginTop: 30}}>
                                        Kupikir ini cukup karena waktu kita tidak banyak. Daftarlah dan sampai bertemu
                                        <b> Sabtu, 5 Desember 2020 pukul 13.00 WIB.</b>
                                    </p>
                                    <p style={{marginTop: 30, marginBottom: 0}}>
                                        Salam,
                                    </p>
                                    <p>
                                        Aku, si Penulis <i>Surga Anjing Liar</i>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </Row>
                <Row style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: "center",

                }}>
                    <Col xl={10} style={{display: 'flex', justifyContent: 'center', alignContent: "center"}}>
                        <img src={require("../../asset/surgaanjingliar/footeradimas.png")}
                             style={{
                                 width: '100%',
                                 maxWidth: 1136,
                                 height: "100%",
                                 backgroundBlendMode: "color",
                             }}/>
                    </Col>
                </Row>
                <Row style={{
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: '#d0484f',
                    width: '100%',
                    borderTop: '1px solid #d0484f',
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 10,

                }}>
                    <Col style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row'
                    }}>
                        <a href="http://bit.ly/terkaanjingliar">
                            <Button style={{
                                fontWeight: 'bolder',
                                fontSize: '1.05em',
                                color: 'lightgrey',
                                backgroundColor: '#a20b16',
                                paddingTop: 10,
                                paddingBottom: 10,
                                margin: 3,
                                width: 150,
                                borderWidth: 0
                            }}>
                                {isEN ? 'Daftar' : 'Daftar'}
                            </Button>
                        </a>
                    </Col>
                </Row>
            </Container>
        </PageWrapper>
    )
}
