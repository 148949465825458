import Container from "react-bootstrap/Container";
import React, {useEffect} from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";

export default function Info(props) {

    useEffect(() => {
        ApplicationActivities.sendActivity({
            type: "INFO",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "Mobile Webiste" : "Website"
        })
    }, [])

    return (
        <PageWrapper headerActive={"support"}>
            <Container style={{marginTop: 80}}>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: 24, fontWeight: 'bold'}}>Hubungi Kami</span>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <span style={{width: 80, height: 4, backgroundColor: Palette.MAROON, borderRadius: 5}}/>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <Col md={12}>
                        <p style={{textAlign : "center"}}>
                            Menemui Masalah?
                            <br/>
                            <br/>
                            patjarmin siap membantu. Kirim surel beserta masalah teknismu ke <br/>
                            <b style={{fontSize : "1.2em"}}>renjana@patjarmerah.com</b>

                            <br/>
                            <br/>
                            atau dm instagram
                            <br/>

                            <b style={{fontSize : "1.2em"}}>@patjarmerah_id</b>

                            <br/>
                            <br/>
                            <a style={{
                                marginTop: "0.3em",
                            }}
                               target="_blank" rel="noopener noreferrer" href={"https://www.instagram.com/patjarmerah_id/"}>
                                <img
                                    style={{
                                        height: "3em",
                                        objectFit: "contain"
                                    }}
                                    src={require("../../asset/logo-instagram.png")}/>
                            </a>
                        </p>
                    </Col>


                </Row>

                <div style={{
                    width: "100%",
                    backgroundColor: "lightGrey",
                    // backgroundColor: "green",
                    height: "1px",
                    marginTop: "3em",
                    marginBottom: "3em"
                }}/>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: 24, fontWeight: 'bold'}}>Kebijakan Privasi</span>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <span style={{width: 80, height: 4, backgroundColor: Palette.MAROON, borderRadius: 5}}/>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <Col md={12}>
                        <p> Di situs web patjarmerah, dapat diakses dari https://patjarmerah.com/, salah satu prioritas utama kami adalah privasi pengunjung kami. Dokumen Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan dan dicatat oleh situs web patjarmerah dan bagaimana kami menggunakannya. </p>

                        <p> Jika Anda memiliki pertanyaan tambahan atau memerlukan informasi lebih lanjut tentang Kebijakan Privasi kami, jangan ragu untuk menghubungi kami. </p>

                        <h2> Peraturan Perlindungan Data Umum (GDPR) </h2>
                        <p> Kami adalah Pengontrol Data dari informasi Anda. </p>

                        <p> dasar hukum patjarmerah untuk mengumpulkan dan menggunakan informasi pribadi yang dijelaskan dalam Kebijakan Privasi ini tergantung pada Informasi Pribadi yang kami kumpulkan dan konteks spesifik tempat kami mengumpulkan informasi: </p>
                        <ul>
                            <li> patjarmerah perlu melakukan kontrak dengan Anda </li>
                            <li> Anda telah memberikan izin patjarmerah untuk melakukannya </li>
                            <li> Memproses informasi pribadi Anda adalah kepentingan sah patjarmerah </li>
                            <li> patjarmerah perlu mematuhi hukum </li>
                        </ul>

                        <p> patjarmerah akan menyimpan informasi pribadi Anda hanya selama diperlukan untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan informasi Anda sejauh yang diperlukan untuk mematuhi kewajiban hukum kami, menyelesaikan perselisihan, dan menegakkan kebijakan kami. </p>

                        <p> Jika Anda penduduk Wilayah Ekonomi Eropa (EEA), Anda memiliki hak perlindungan data tertentu. Jika Anda ingin diberi tahu Informasi Pribadi apa yang kami miliki tentang Anda dan jika Anda ingin informasi itu dihapus dari sistem kami, silakan hubungi kami. Kebijakan Privasi kami dibuat dengan bantuan <a href="https://www.gdprprivacypolicy.net/"> Generator Kebijakan Privasi GDPR </a> dan <a href = "https: //www.app-privacy -policy.com/app-privacy-policy-generator/">App Pembuat Kebijakan Privasi </a>. </p>

                        <p> Dalam keadaan tertentu, Anda memiliki hak perlindungan data berikut: </p>
                        <ul>
                            <li> Hak untuk mengakses, memperbarui, atau menghapus informasi yang kami miliki tentang Anda. </li>
                            <li> Hak perbaikan. </li>
                            <li> Hak untuk menolak. </li>
                            <li> Hak pembatasan. </li>
                            <li> Hak portabilitas data </li>
                            <li> Hak untuk menarik persetujuan </li>
                        </ul>

                        <h2> Log File </h2>

                        <p> situs web patjarmerah mengikuti prosedur standar menggunakan file log. File-file ini mencatat pengunjung ketika mereka mengunjungi situs web. Semua perusahaan hosting melakukan ini dan bagian dari analisis layanan hosting. Informasi yang dikumpulkan oleh file log termasuk alamat protokol internet (IP), tipe browser, Penyedia Layanan Internet (ISP), cap tanggal dan waktu, halaman rujukan / keluar, dan mungkin jumlah klik. Ini tidak terkait dengan informasi apa pun yang dapat diidentifikasi secara pribadi. Tujuan dari informasi ini adalah untuk menganalisis tren, mengelola situs, melacak pergerakan pengguna di situs web, dan mengumpulkan informasi demografis. </p>

                        <h2> Cookie dan Beacon Web </h2>

                        <p> Seperti situs web lainnya, situs web patjarmerah menggunakan 'cookies'. Cookie ini digunakan untuk menyimpan informasi termasuk preferensi pengunjung, dan halaman-halaman di situs web yang diakses atau dikunjungi pengunjung. Informasi ini digunakan untuk mengoptimalkan pengalaman pengguna dengan menyesuaikan konten halaman web kami berdasarkan jenis browser pengunjung dan / atau informasi lainnya. </p>

                        <p> Untuk informasi lebih lanjut tentang cookie, harap baca <a href="https://www.cookieconsent.com/what-are-cookies/"> "What Are Cookies" </a>. </p>



                        <h2> Kebijakan Privasi </h2>

                        <p> Anda dapat berkonsultasi daftar ini untuk menemukan Kebijakan Privasi untuk masing-masing mitra iklan situs web patjarmerah. </p>

                        <p> Server iklan pihak ketiga atau jaringan iklan menggunakan teknologi seperti cookie, JavaScript, atau Web Beacon yang digunakan dalam iklan masing-masing dan tautan yang muncul di situs web patjarmerah, yang dikirim langsung ke browser pengguna. Mereka secara otomatis menerima alamat IP Anda ketika ini terjadi. Teknologi ini digunakan untuk mengukur efektivitas kampanye iklan mereka dan / atau untuk mempersonalisasi konten iklan yang Anda lihat di situs web yang Anda kunjungi. </p>

                        <p> Perhatikan bahwa situs web patjarmerah tidak memiliki akses atau kontrol terhadap cookie ini yang digunakan oleh pengiklan pihak ketiga. </p>

                        <h2> Kebijakan Privasi Pihak Ketiga </h2>

                        <p> Kebijakan Privasi situs web patjarmerah tidak berlaku untuk pengiklan atau situs web lain. Karenanya, kami menyarankan Anda untuk berkonsultasi dengan masing-masing Kebijakan Privasi dari server iklan pihak ketiga ini untuk informasi yang lebih terperinci. Ini mungkin termasuk praktik dan instruksi mereka tentang cara menyisih dari opsi tertentu. </p>

                        <p> Anda dapat memilih untuk menonaktifkan cookie melalui opsi browser individual Anda. Untuk mengetahui informasi lebih rinci tentang manajemen cookie dengan browser web tertentu, dapat ditemukan di situs web masing-masing browser. </p>

                        <h2> Informasi Anak-Anak </h2>

                        <p> Bagian lain dari prioritas kami adalah menambahkan perlindungan untuk anak-anak saat menggunakan internet. Kami mendorong orang tua dan wali untuk mengamati, berpartisipasi, dan / atau memantau dan membimbing aktivitas online mereka. </p>

                        <p> situs web patjarmerah tidak secara sadar mengumpulkan Informasi Identifikasi Pribadi apa pun dari anak di bawah 13 tahun. Jika Anda berpikir bahwa anak Anda memberikan informasi semacam ini di situs web kami, kami sangat menganjurkan Anda untuk segera menghubungi kami dan kami akan melakukan upaya terbaik untuk segera menghapus informasi tersebut dari catatan kami. </p>

                        <h2> Hanya Kebijakan Privasi Online </h2>

                        <p> Kebijakan Privasi kami dibuat di GDPRPrivacyPolicy.net) hanya berlaku untuk aktivitas online kami dan berlaku untuk pengunjung situs web kami sehubungan dengan informasi yang mereka bagikan dan / atau kumpulkan di situs web patjarmerah. Kebijakan ini tidak berlaku untuk informasi apa pun yang dikumpulkan secara offline atau melalui saluran selain dari situs web ini. <a href="https://gdprprivacypolicy.net"> Kebijakan Privasi GDPR kami </a> dihasilkan dari Generator Kebijakan Privasi GDPR. </p>

                        <h2> Persetujuan </h2>

                        <p> Dengan menggunakan situs web kami, Anda dengan ini menyetujui Kebijakan Privasi kami dan menyetujui persyaratannya. </p>
                    </Col>
                </Row>

                <div style={{
                    width: "100%",
                    backgroundColor: "lightGrey",
                    // backgroundColor: "green",
                    height: "1px",
                    marginTop: "3em",
                    marginBottom: "3em"
                }}/>

                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: 24, fontWeight: 'bold'}}>Refund Policy</span>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <span style={{width: 80, height: 4, backgroundColor: Palette.MAROON, borderRadius: 5}}/>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20}}>
                    <Col md={12}>
                        <p style={{textAlign : "center"}}>
                            Tiket yang sudah dibeli tidak dapat dikembalikan.
                        </p>
                    </Col>
                </Row>

            </Container>
        </PageWrapper>
    )
}
