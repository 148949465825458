import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import PapermoonTicketNode from "../reusable/PapermoonTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ListGroup from "react-bootstrap/ListGroup";
import {Link} from "react-router-dom";

export default function SurgaAnjingLiarDetail(props) {
    const [isFirstCardHover, setFirstCardHover] = useState(false);
    const [isSecondCardHover, setSecondCardHover] = useState(false);
    const [isThirdCardHover, setThirdCardHover] = useState(false);
    const [isFourthCardHover, setFourthCardHover] = useState(false);
    const descriptionRef = useRef(null);
    const buyBookRef = useRef(null);
    const buyBookAndTicketRef = useRef(null);
    const [isLanguageInfoOpen, setIsLanguageInfoOpen] = useState(true);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = cookie.lang !== "id";
    const [dimensions, setDimensions] = useState({width: window.innerWidth, height: window.innerHeight});
    const [isWatchLaunchingBtnShown, setWatchLaunchingBtnShown] = useState(false);
    const [isWatchIntimateBtnShown, setWatchIntimateBtnShown] = useState(false);
    const [intimateId, setIntimateId] = useState(null);

    const handleResize = () => {
        console.log(window.innerHeight)
        setDimensions({width: window.innerWidth, height: window.innerHeight})
    }

    useEffect(() => {
        new Session().getRumasuarSessions().then(res => {
            res.map(session => {
                if (session.id === 222 && session.status === 'ACCEPTED') {
                    //222: Launching
                    setWatchLaunchingBtnShown(true);
                } else if ((session.id === 223 || session.id === 224) && session.status === 'ACCEPTED') {
                    //223/224: Intimate I/II
                    setIntimateId(session.id)
                    setWatchIntimateBtnShown(true)
                }
            })
        }).catch(err => console.log(err))

        window.addEventListener('resize', handleResize)

        if (props.location.state) {
            if (props.location.state.scrollTo === 'description') window.scrollTo({
                left: 0,
                top: descriptionRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
            if (props.location.state.scrollTo === 'buyBook') window.scrollTo({
                left: 0,
                top: buyBookRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
            else if (props.location.state.scrollTo === 'buyBookAndTicket') window.scrollTo({
                left: 0,
                top: buyBookAndTicketRef.current.offsetTop - 200,
                behavior: 'smooth'
            })
        }

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const openSessionStream = async (id) => {
        try {
            const response = await new Session().getSessionStream(id);

            console.log(response)

            if (!response.expose) {
                alert('Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai')
            } else {
                window.open(response.stream_url, "_blank");
            }
        } catch (e) {
            console.log(e)
        }
    }

    const popover = (
        <Popover>
            <Popover.Content style={{
                display : "flex",
                flexDirection  :"column",
                alignItems :"center"
            }}>
                <Link to={"/surgaanjingliar/detail"}>
                    Surga Anjing Liar
                </Link>
                <Link to={"/perjamuan-pulang"}>
                    Perjamuan Pulang
                </Link>
            </Popover.Content>
        </Popover>
    );

    return (
        <PageWrapper
            headerActive={'surgaanjingliar-detail'}
        >
            <Container fluid style={{
                // background: `url('/image/rumasuar-decoration.jpeg')`,
                // backgroundRepeat: "no-repeat",
                // backgroundSize: dimensions.width < 1500 ? '0px 0px' : `200px 544px`,
                // backgroundPosition: 'calc(100% - 100px) 70%',
                // backgroundAttachment: 'fixed'
            }}>
                <Container style={{
                    paddingTop: '1em', marginBottom: 70,
                }}>
                    <Modal
                        size="sm"
                        onHide={() => {
                            setIsLanguageInfoOpen(false)
                        }}
                        show={cookie.lang && isEN && isLanguageInfoOpen}
                    >
                        <Modal.Header closeButton>
                            Information
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                This page is only available in Indonesian.
                            </div>
                        </Modal.Body>

                    </Modal>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{display: 'flex', justifyContent: 'center', marginBottom: 25}}>
                        <Col md={12}>
                            <img src={require("../../asset/surgaanjingliar/banner-surga anjing liar-soon.jpg")}
                                 style={{
                                     width: '100%',
                                     borderRadius: 5,
                                     objectFit: "contain",
                                     maxHeight: "50vh"
                                 }}/>

                        </Col>
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: isMobile ? null : 900,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 0%",
                        }}>

                            <p style={{
                                fontSize: '1.3em',
                                fontWeight: 'bolder',
                                fontFamily: 'Baskervville'
                            }} ref={descriptionRef}>DESKRIPSI</p>

                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Baskervville'
                                }}>JUDUL</p>

                                <div style={{marginTop: -5, fontFamily: 'Baskervville'}}>
                                    Surga Anjing Liar
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Baskervville'
                                }}>KARYA</p>

                                <div style={{marginTop: -5, fontFamily: 'Baskervville'}}>
                                    Adimas Immanuel
                                </div>

                            </div>

                            <div style={{fontFamily: 'Baskervville', marginBottom: 35}}>
                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Baskervville'
                                }}>
                                    SINOPSIS
                                </p>

                                Ranapati adalah desa terpencil di kaki gunung yang dikenal untuk dua hal: praktik ilmu
                                gaib dan tempat kelahiran banyak penjahat. Sebuah potret desa di Jawa Tengah dari masa
                                kemerdekaan hingga era reformasi yang berusaha menyiasati perkembangan zaman dengan
                                caranya sendiri.
                                <br/><br/>
                                Suatu hari, sebuah peristiwa tragis yang sudah diramalkan terjadi dan warga bersepakat
                                menentukan jalan keluar sendiri. Merasa ada yang salah dengan banyak hal, tokoh kita,
                                seorang penulis yang bersih hatinya, berusaha menentang dengan menuliskan hal-hal yang
                                ia ketahui.
                                <br/><br/>
                                <i>Surga Anjing Liar</i> gambaran masyarakat desa yang bergulat dengan banyak keputusan
                                hidup yang ganjil dan tak masuk akal. Tempat kegilaan, ambisi dan kekuasaan mengaburkan
                                batas moral. Dalam kondisi itu, penjahat paling keji dalam sejarah desa lahir dan
                                dirayakan.

                                <br/><br/>
                                ---
                                <br/><br/>

                                “Diawali kematian tragis salah satu tokohnya, Surga Anjing Liar menyeret pembaca ke alur
                                yang menghidupkan penasaran. Dengan menyingkap misteri tokoh-tokoh, rangkaian cerita
                                yang diselingi renungan kritis dan liris ini coba menggambarkan masyarakat di tengah
                                zaman yang kian mengaburkan batas-batas moral.” — (Joko Pinurbo, penyair)
                                <br/><br/>
                                “Adimas Immanuel membangun dunia imajiner Ranapati dari ingatan personal dan dari
                                kegelisahannya sebagai manusia pengarang. Hasilnya semesta fiksi yang misterius
                                sekaligus memikat, yang lugas sekaligus puitis. Novel pertama seorang penyair yang layak
                                dibaca.” — (Okky Madasari, novelis)
                                <br/><br/>

                            </div>


                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}
                            >

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Baskervville',
                                    textTransform : "uppercase"
                                }}>akademipatjar : Puisi dan Narasi</p>

                                <div style={{marginTop: -5, fontFamily: 'Baskervville'}}>
                                    Seringkali, ketika telah mengolah gagasan menjadi sesuatu, kita bertanya-tanya
                                    apakah gagasan tersebut telah utuh tersampaikan oleh karya tersebut. Apakah jika
                                    karya tersebut kita alihwahana menjadi bentuk lain, gagasan kita akan lebih
                                    mendapat ruang? Atau malah justru sebaliknya?
                                    <br/><br/>
                                    Dari pengalaman menulis novel pertamanya, Surga Anjing Liar, Adimas Immanuel
                                    akan berbagi tentang bagaimana ia memilah ide untuk kemudian menumpahkannya
                                    menjadi puisi atau narasi cerita panjang. Lewat kelas ini, kamu diharapkan dapat
                                    menentukan apa medium yang paling tepat untuk karyamu.
                                    <br/><br/>
                                </div>

                            </div>


                            <div style={{marginBottom: 35, textAlign: 'left'}} ref={buyBookRef}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',
                                    fontFamily: 'Baskervville'
                                }}>
                                    PAKET <span style={{fontFamily: 'Baskervville', fontSize: '.9em', color: 'grey'}}>(Tekan paket untuk diarahkan ke halaman toko)</span>
                                </p>

                                <Row>
                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10}}
                                         onClick={() => window.open('https://shopee.co.id/Parade-Anjing-Liar-Adimas-Immanuel-Bukune-i.34379470.6277685682', "_blank")}>
                                        <div style={{
                                            cursor: 'pointer',
                                            boxShadow: isFirstCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}
                                             onMouseEnter={() => setFirstCardHover(true)}
                                             onMouseLeave={() => setFirstCardHover(false)}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={require("../../asset/surgaanjingliar/paket1New.jpg")} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isFirstCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Paket 1
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp250.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Baskervville', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Baskervville',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Bertanda Tangan - Slayer - Kaus - Poster - Bloknot - Buku Puisi
                                                Tanah Ranapati - Stiker Set - Tiket Kelas Menulis Adimas Immanuel
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10, cursor: 'pointer'}}
                                         onClick={() => window.open('https://shopee.co.id/Parade-Anjing-Liar-Adimas-Immanuel-Bukune-i.34379470.6277685682', "_blank")}
                                         onMouseEnter={() => setSecondCardHover(true)}
                                         onMouseLeave={() => setSecondCardHover(false)}>
                                        <div style={{
                                            boxShadow: isSecondCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={require("../../asset/surgaanjingliar/paket2New.jpg")} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isSecondCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Paket 2
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp150.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Baskervville', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Baskervville',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku Bertanda Tangan - Poster - Bloknot - Buku Puisi Tanah Ranapati -
                                                Stiker Set - Tiket Kelas Menulis Adimas Immanuel
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} style={{paddingTop: 15, paddingBottom: 10, cursor: 'pointer'}}
                                         onClick={() => window.open('https://shopee.co.id/Parade-Anjing-Liar-Adimas-Immanuel-Bukune-i.34379470.6277685682', "_blank")}
                                         onMouseEnter={() => setThirdCardHover(true)}
                                         onMouseLeave={() => setThirdCardHover(false)}
                                         ref={buyBookAndTicketRef}>
                                        <div style={{
                                            boxShadow: isThirdCardHover ? '0px 0px 10px 0px rgba(194,194,194,1)' : '0px 0px 3px 0px rgba(194,194,194,1)',
                                            borderRadius: 5,
                                            height: '100%'
                                        }}>
                                            <div style={{overflow: 'hidden'}}>
                                                <img src={require("../../asset/surgaanjingliar/paket3New.jpg")} style={{
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    width: '100%',
                                                    transform: isThirdCardHover ? 'scale(1.25)' : 'none'
                                                }}/>
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Semibold',
                                                fontSize: '1.1em',
                                                paddingTop: 5,
                                                paddingLeft: 20
                                            }}>
                                                Paket 3
                                            </div>

                                            <div style={{
                                                fontFamily: 'Signika-Regular',
                                                paddingTop: 5,
                                                paddingLeft: 20,
                                                color: Palette.MAROON
                                            }}>
                                                Rp100.000
                                            </div>

                                            <div style={{height: 1, backgroundColor: '#e3e3e3', marginTop: 5}}/>

                                            <div style={{fontFamily: 'Baskervville', paddingTop: 5, paddingLeft: 20}}>
                                                Isi Paket:
                                            </div>

                                            <div style={{
                                                fontFamily: 'Baskervville',
                                                paddingTop: 3,
                                                paddingLeft: 20,
                                                paddingRight: 20,
                                                color: 'grey',
                                                paddingBottom: 10
                                            }}>
                                                Buku - Stiker - Tiket Kelas Menulis Adimas Immanuel
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </div>

                            <div
                                style={{
                                    // marginTop: 15,
                                    marginBottom: 35,
                                    // paddingBottom: 15,
                                    // paddingTop: 15,
                                    textAlign: 'left',
                                }}>

                                <div style={{
                                    marginBottom: 35, textAlign: 'left',

                                }}>

                                    <p style={{
                                        fontSize: '1.1em',
                                        fontWeight: 'bolder',
                                        textAlign: 'left',
                                        fontFamily: 'Baskervville'
                                    }}>WAKTU</p>

                                    <div style={{marginTop: -5, fontFamily: 'Baskervville'}}>
                                        - 25-30 Januari 2020 - Prapesan<br/>
                                        - 8-12 Februari 2020 - Pengiriman Tiket Kelas Virtual<br/>
                                        - 14 Februari 2020 - Kelas Menulis Virtual Bersama Kelas Menulis Adimas Immanuel<br/>
                                    </div>

                                </div>

                            </div>


                            {/*{isEN ? "Is Supported By patjarmerah, Kotasis, dan Intellivent" : "Didukung oleh patjarmerah, Kotasis, dan Intellivent"}*/}

                        </div>
                    </Row>
                </Container>

            </Container>
        </PageWrapper>
    )
}


