import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import User from "../../models/User";
import GlobalData from "../../util/GlobalData";
import moment from "moment";
import 'moment/locale/id'
import Session from "../../models/Session";
import PaymentGuideModal from "./PaymentGuideModal";
import PaymentConfirmationModal from "./PaymentConfirmationModal";
import textFormatter from "../../util/textFormatter";
import { render } from 'react-dom';

import { Parser } from 'html-to-react'
import momentTz from "moment-timezone";
import { isMobile } from "react-device-detect";
import { AiOutlineClose } from "react-icons/all";
import QRCode from "react-qr-code";

export default class SessionModal extends Component {
    defaultRegisterState = {
        passwordMismatch: false,
        emailIsRegistered: false,
    }

    refHeight;

    constructor(props) {
        super(props);

        this.state = {
            loginMode: true,
            loginCred: {},
            registerCred: {
                date_of_birth: ""
            },
            registerState: {
                ...this.defaultRegisterState
            },
            error: null,
            isPaymentGuideVisible: false,
            isPaymentConfirmationVisible: false,
            paymentConfirmationUploaded: false,
            show: true,
            registeredCount: props.session ? props.session.quota : null,
            isPending: false,
            isConfirmingCancel: false,
            isTicketModalShown: false,
            quantity: 0
        }
    }

    /*
    async getSessionRemainingQuota(){

        console.log("fetching")

        let sessionModel = new Session();
        let result = await sessionModel.getSessionRemainingQuota(this.props.session.id)

        this.setState({
            registeredCount : (this.props.session.quota - result.quota_remaining)
        })

        console.log("total quota " +  (this.props.session.quota - result.quota_remaining)  )

        console.log(result)
    }*/

    componentDidUpdate(prevProps, prevState) {

        // if(prevProps.session !== this.props.session && this.props.session){
        //     // this.getSessionRemainingQuota()
        // }

        if (this.state.referenceHeight && this.state.referenceHeight === prevState.referenceHeight) {
            console.log("nothing to do")
        } else {
            const refComponent = document.getElementById('reference-component');
            if (refComponent) {
                this.refHeight = refComponent.height

                /*
                console.log(refComponent.height)
                this.setState({
                    referenceHeight: refComponent.height
                })
                */

            }
        }
    }


    buyTicket = async (sessionId) => {
        if (GlobalData.token) {
            console.log("fetching")
            const sessionModel = new Session();

            let session = this.props.session

            try {
                let response
                if (session.max_ticket_per_buy > 1) {
                    response = await sessionModel.buyTicketMulti(sessionId, this.state.quantity);
                } else {
                    response = await sessionModel.buyTicket(sessionId);
                }

                console.log("test", response)

                if (response.registration_status === "PENDING") {
                    alert('Kuota habis. Anda akan dikabari apabila kuota tersedia.');
                    window.location.reload()
                    // this.setState({isPending: true});
                    this.props.setPending();
                } else if (response.success && response.snap_payment_url !== "undefined" && response.snap_payment_url) {
                    // window.open(response.snap_payment_url, "_self");
                    // window.location = response.snap_payment_url
                    // window.location.assign(response.snap_payment_url)

                    console.log("im here")
                    //
                    window.location = response.snap_payment_url
                    this.props.showPaymentModal(response.snap_payment_url);

                } else if (session.price === 0 && response.success) {
                    window.location.reload()
                }


            } catch (e) {
                console.log(e)
            }
        } else {
            this.props.showLogin()
        }
    }


    rebuyTicket = async (sessionId) => {
        const sessionModel = new Session();

        try {
            const response = await sessionModel.cancel(sessionId);

            console.log(response)

            if (response.success) this.buyTicket(sessionId)
        } catch (e) {
            console.log(e)
        }
    }

    openPaymentModal = (paymentUrl) => {
        this.setState({ paymentUrl })
    }

    async processClick() {

        let session = this.props.session
        let info = JSON.parse(session.additional_information)

        console.log(session)

        if (info.type === "instagram") {

            return window.open(info.stream_url, "_blank");

            // if (session.status) {
            //
            //     const sessionModel = new Session();
            //     try {
            //         const response = await sessionModel.getSessionStream(session.id);
            //
            //         console.log(response)
            //
            //         if (!response.expose) {
            //             alert('Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai')
            //         } else {
            //             window.open(response.stream_url, "_blank");
            //         }
            //     }catch(e){
            //         console.log(e)
            //     }
            //
            // } else {
            //     const sessionModel = new Session();
            //
            //     try {
            //
            //         const ticketResponse = await sessionModel.buyTicket(session.id);
            //         const response = await sessionModel.getSessionStream(session.id);
            //
            //         console.log(response)
            //
            //         if (!response.expose) {
            //             alert('Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai')
            //         } else {
            //             window.open(response.stream_url, "_blank");
            //         }
            //
            //     }catch(e){
            //         console.log(e)
            //     }
            //
            // }
            // return
        }

        if (session.online_payment) {
            if (session.status === 'WAITING') window.open(session.registration_info.snap_payment_url, "_blank")
            else if (session.price === 0 || session.status === 'ACCEPTED') {
                if (session.online_mode) {
                    window.open(session.stream_url, "_blank")
                } else {
                    this.setState({
                        isTicketModalShown: true
                    })
                }
            }
            else if (session.status === 'REJECTED') this.rebuyTicket(session.id)
            else if (session.price > 0) this.buyTicket(session.id)
        } else {
            if (session.status === 'ACCEPTED') {
                if (session.online_mode) {
                    const sessionModel = new Session();

                    try {
                        const response = await sessionModel.getSessionStream(session.id);

                        console.log(response)

                        if (!response.expose) {
                            alert('Tautan belum tersedia! Silakan coba kembali 3 jam sebelum sesi dimulai')
                        } else {
                            window.open(response.stream_url, "_blank");
                        }
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    this.setState({
                        isTicketModalShown: true
                    })
                }

            } else if (info.type === "book") {
                if (session.online_mode) {
                    window.open(info.url, "_blank")
                } else {
                    this.setState({
                        isTicketModalShown: true
                    })
                }
            } else if (session.status === 'REJECTED') {
                this.rebuyTicket(session.id)
            } else if (!session.status) {
                this.buyTicket(session.id)
            }
        }


    }

    openGuidePage() {
        this.setState({
            isPaymentGuideVisible: true,
            show: false
        })
    }

    openPaymentConfirmation() {
        if (GlobalData.token) {
            this.setState({
                isPaymentConfirmationVisible: true,
                show: false
            })
        } else {
            this.props.showLogin();
        }
    }

    renderAlertText() {

        let session = this.props.session
        let info = JSON.parse(session.additional_information)

        if (info.type === "book" || info.type === "donation") {

            if (this.state.paymentConfirmationUploaded || session.status === "WAITING") {
                return <div style={{
                    backgroundColor: '#fffeec',
                    color: 'black',
                    fontSize: '0.7em',
                    border: '1px solid #ebd98b',
                    borderRadius: 6,
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    textAlign: 'center',
                    marginBottom: '3px'
                }}>
                    Bukti {info.type === "donation" ? "donasi" : "pembayaran"} telah diterima. Harap tunggu 1 x 24 jam
                    untuk konfirmasi dari kami.
                </div>
            }

            if (session.status === "REJECTED") {
                return <div style={{
                    backgroundColor: '#fffeec',
                    color: 'black',
                    fontSize: '0.7em',
                    border: '1px solid #ebd98b',
                    borderRadius: 6,
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    textAlign: 'center',
                    marginBottom: '3px'
                }}>
                    Bukti {info.type === "donation" ? "donasi" : "pembayaran"} tidak valid. Harap melakukan registrasi
                    ulang.
                </div>
            }

            if (session.status === "PENDING" || this.state.isPending) {
                return <div style={{
                    backgroundColor: '#fffeec',
                    color: 'black',
                    fontSize: '0.7em',
                    border: '1px solid #ebd98b',
                    borderRadius: 6,
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    textAlign: 'center',
                    marginBottom: '3px'
                }}>
                    Kuota sesi sudah abis. Anda akan diberikan notifikasi jika ada yang membatalkan pendaftaran.
                </div>
            }


        }
    }

    changePaymentMethod = async (sessionId) => {
        const sessionModel = new Session();

        try {
            const response = await sessionModel.changePaymentMethod(sessionId);

            if (response.success) {
                window.location = response.snap_payment_url
                this.props.showPaymentModal(response.snap_payment_url);
            }

            console.log(response)
        } catch (e) {
            console.log(e)
        }
    }

    async cancelRegistration() {
        let sessionModel = new Session()
        let session = this.props.session
        let result = await sessionModel.cancel(session.id);
        window.location.reload()
    }

    renderActionButton() {

        let session = this.props.session
        let info = JSON.parse(session.additional_information)

        console.log("info", info)

        if (!new moment(session.start_time).isAfter(new Date()) && session.status !== "ACCEPTED" && info.type !== "instagram") {
            return <Button
                size={"sm"} block variant={"outline-danger"} disabled>
                Pendaftaran Sesi Ini Telah Ditutup
            </Button>
        }


        if (!session.allow_registration) {
            return <Button
                size={"sm"} block variant={"outline-danger"} disabled>
                Pendaftaran Sesi Ini Belum Dibuka
            </Button>
        }

        if (info.type === "external_link") {
            if (session.status !== 'ACCEPTED') {
                return <div
                    style={{ width: "100%", display: "flex" }}>
                    <a
                        style={{ width: "100%" }}
                        target={"_blank"}
                        href={info.external_link}>
                        <Button
                            style={{ width: "100%" }}
                            size={"sm"} block variant={"outline-danger"}>
                            Daftar
                        </Button>
                    </a>
                </div>
            } else {
                return <>
                    <Button
                        onClick={() => {
                            this.processClick()
                        }}
                        size={"sm"} block variant={"success"}>
                        Tampilkan QR
                    </Button>
                </>
            }
        }

        if (info.type === "book") {
            if (session.status !== 'ACCEPTED') {
                return <>
                    <Button
                        onClick={() => {
                            this.openGuidePage()
                        }}
                        size={"sm"} block variant={"outline-info"}>
                        Petunjuk Pendaftaran
                    </Button>
                    <Button
                        onClick={() => {
                            this.openPaymentConfirmation()
                        }}
                        size={"sm"} block variant={"outline-danger"}>
                        {session.status === 'WAITING' ? 'Unggah Bukti Baru' : 'Unggah Bukti'}
                    </Button>
                </>
            } else {
                return <>
                    <Button
                        onClick={() => {
                            this.processClick()
                        }}
                        size={"sm"} block variant={"success"}>
                        {session.online_mode ? 'Masuk Sesi' : 'Tampilkan QR'}
                    </Button>
                </>
            }
        }

        if (info.type === "donation") {
            if (session.status !== 'ACCEPTED') {
                return <>
                    <Button
                        onClick={() => {
                            this.openGuidePage()
                        }}
                        size={"sm"} block variant={"outline-info"}>
                        Petunjuk Pendaftaran
                    </Button>
                    <Button
                        onClick={() => {
                            this.openPaymentConfirmation()
                        }}
                        size={"sm"} block variant={"outline-danger"}>
                        {session.status === 'WAITING' ? 'Unggah Bukti Baru' : 'Unggah Bukti'}
                    </Button>
                </>
            } else {
                return <>
                    <Button
                        onClick={() => {
                            this.processClick()
                        }}
                        size={"sm"} block variant={"success"}>
                        Tonton
                    </Button>
                </>
            }
        }

        if (info.type === "instagram") {
            return <>
                {/*<Button*/}
                {/*    onClick={() => {*/}
                {/*        window.open(info.url);*/}
                {/*    }}*/}
                {/*    size={"sm"} block variant={"outline-info"}>*/}
                {/*    Ikut Berdonasi*/}
                {/*</Button>*/}
                <Button
                    onClick={() => {
                        this.processClick()
                    }}
                    size={"sm"} block variant={"success"}>
                    Tonton
                </Button>
            </>
        }

        if (session.price === 0 && info.type !== "book" && info.type !== "donation" && info.type !== "instagram") {
            return <>
                {session.status !== 'PENDING' && !this.state.isPending ?
                    <>
                        <Button
                            disabled={this.state.quantity === 0 && !session.status && session.max_ticket_per_buy > 1}
                            onClick={() => {
                                this.processClick()
                            }}
                            size={"sm"} block variant={session.status === 'ACCEPTED' ? "success" : "outline-danger"}>
                            {session.status === 'ACCEPTED' && session.online_mode ? 'Tonton' : session.status === 'ACCEPTED' && !session.online_mode ? 'Tampilkan QR' : this.props.session.status === "REJECTED" ? 'Daftar Ulang' : 'Daftar'}
                        </Button>
                        {
                            session.status === 'ACCEPTED'
                            && <Button
                                variant={"outline-danger"}
                                size={"sm"}
                                style={{ marginTop: 5 }}
                                onClick={() => this.setState({ ...this.state, isConfirmingCancel: true })}>
                                Batalkan Pendaftaran
                            </Button>
                        }
                    </>
                    : null}


            </>
        }

        if (session.online_payment) {

            return <>

                {session.status === 'WAITING' ?
                    <Button
                        size={"sm"} block variant={"outline-info"}
                        onClick={() => this.changePaymentMethod(session.id)}>
                        Ganti Metode Pembayaran
                    </Button> : null}

                {session.status !== 'PENDING' && !this.state.isPending ?
                    <>
                        {/* <Button
                            onClick={() => {
                                this.processClick()
                            }}
                            size={"sm"} block variant={session.status === 'ACCEPTED' ? "success" : "outline-danger"}>
                            {(session.price === 0 || session.status === 'ACCEPTED') && session.online_mode ? 'Tonton' : (session.price === 0 || session.status === 'ACCEPTED') && !session.online_mode ? 'Tampilkan QR' : this.props.session.status === 'WAITING' || !this.props.session.status ? 'Bayar' : this.props.session.status === "REJECTED" ? 'Daftar Ulang' : 'Daftar Sekarang!'}
                        </Button> */}
                        <Button
                            disabled={this.state.quantity === 0 && !session.status && session.max_ticket_per_buy > 1}
                            style={{ marginTop: 10 }}
                            onClick={() => {
                                this.processClick()
                            }}
                            size={"sm"} block variant={session.status === 'ACCEPTED' ? "success" : "outline-danger"}>
                            {(session.price === 0 || session.status === 'ACCEPTED') && session.online_mode ? 'Tonton' : (session.price === 0 || session.status === 'ACCEPTED') && !session.online_mode ? 'Tampilkan QR' : this.props.session.status === 'WAITING' || !this.props.session.status ? 'Bayar' : this.props.session.status === "REJECTED" ? 'Daftar Ulang' : 'Daftar Sekarang!'}
                        </Button>
                    </>
                    : null}
            </>
        }

    }

    renderPrice() {

        let session = this.props.session
        let info = session ? JSON.parse(session.additional_information) : {}

        console.log("SEESH", session)

        if (session.custom_price_string) {
            return <>{session.custom_price_string}</>
        }

        if (info.price) {

            return info.price.map((p, key) => {

                if (p > 0) {
                    return <>Rp{textFormatter.moneyFormatter(p)} {key < info.price.length - 1 ? " / " : ""}</>
                } else {
                    return <>Gratis</>
                }
            })

        } else {
            return <>
                {session.price === 0 ? "GRATIS" : `Rp${textFormatter.moneyFormatter(session.price)}`}
            </>
        }

    }

    renderQuantitySelectorButton() {

        let session = this.props.session

        if (session.max_ticket_per_buy > 1) {
            return <>
                {
                    session.status ? <>
                        <div style={{ fontSize: '0.75em', fontWeight: 'bold' }}>
                            Jumlah Tiket : {session?.registration_info?.amount}
                        </div>
                    </> :
                        <>
                            <div style={{ fontSize: '0.75em', fontWeight: 'bold' }}>
                                Atur Jumlah:
                            </div>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 5, marignBottom: 5 }}>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            quantity: (this.state.quantity - 1 > 0) ? (this.state.quantity - 1) : 0
                                        })
                                    }}
                                    variant="danger" size="sm">
                                    -
                                </Button>
                                <div style={{ width: 50, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {this.state.quantity}
                                </div>
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            quantity: (this.state.quantity + 1 > session.max_ticket_per_buy) ? session.max_ticket_per_buy : this.state.quantity + 1
                                        })
                                    }}
                                    variant="danger" size="sm">
                                    +
                                </Button>
                            </div>
                        </>
                }
            </>
        }

    }

    render() {

        console.log("re rendering")

        let props = this.props
        let session = this.props.session
        let info = session ? JSON.parse(session.additional_information) : {}

        if (!session) return null

        console.log(session.description)

        let htmlParser = new Parser()
        let formattedDescription = htmlParser.parse(session.description)

        let timeZoneString = momentTz.tz.guess(true)

        let timeZoneCity = `(${timeZoneString} Time)`

        if (timeZoneString === "Asia/Jakarta") {
            timeZoneCity = "WIB"
        }


        return <>
            <Modal
                show={this.state.isConfirmingCancel}
                onHide={() => this.setState({ ...this.state, isConfirmingCancel: false })}
            >
                <Modal.Header closeButton>
                    Pembatalan Registrasi
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Jika Anda membatalkan registrasi, kursi akan diberikan kepada peserta di daftar antrean. Anda
                        yakin ingin membatalkan?
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <Button
                        onClick={() => this.setState({ ...this.state, isConfirmingCancel: false })}
                        variant={"danger"}>Tidak</Button>

                    <Button
                        onClick={() => this.cancelRegistration()}
                        variant={"danger"}>Ya</Button>
                </Modal.Footer>
            </Modal>

            <PaymentConfirmationModal
                onClose={(isUploaded) => {
                    this.setState({
                        show: true,
                        isPaymentConfirmationVisible: false,
                        paymentConfirmationUploaded: isUploaded
                    })
                }}
                fetchSessions={this.props.fetchSessions}
                session={this.props.session}
                show={this.state.isPaymentConfirmationVisible}
            />
            <PaymentGuideModal
                onClose={() => {
                    this.setState({
                        show: true,
                        isPaymentGuideVisible: false
                    })
                }}
                show={this.state.isPaymentGuideVisible}
                session={this.props.session}
            />
            <Modal
                {...props}
                size="xl"
                centered
                show={this.props.show && this.state.show && !this.state.isConfirmingCancel && !this.state.isTicketModalShown}
                onHide={() => {
                    //anchor
                    this.setState({
                        paymentConfirmationUploaded: false
                    })
                    this.props.onHide()
                }}
            >
                <Modal.Body>

                    <Row>
                        {
                            isMobile && <Col
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-end",
                                    marginBottom: 10
                                }}
                                md={12}>
                                <AiOutlineClose
                                    onClick={() => {
                                        this.setState({
                                            paymentConfirmationUploaded: false
                                        })
                                        this.props.onHide()
                                    }}
                                    style={{
                                        cursor: "pointer"
                                    }}
                                />
                            </Col>
                        }
                        <Col md={6}>
                            <img
                                id={"reference-component"}
                                style={{
                                    width: "100%",
                                    objectFit: "cover",
                                    minHeight: 300
                                }}
                                onLoad={() => {
                                    console.log("image is loaded")
                                    this.setState({ toggler: this.state.toggler ? this.state.toggler + 1 : 0 })

                                    const refComponent = document.getElementById('reference-component');
                                    this.refHeight = refComponent.height
                                }}
                                src={session.image_url} />
                        </Col>
                        <Col md={6}>
                            <div style={{
                                height: this.refHeight ? this.refHeight : 1,
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                            }}>

                                {
                                    info.type === "book" ?
                                        <Card style={{
                                            marginBottom: "1em",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            padding: "0.3em",
                                            width: "11em",
                                            justifyContent: "center"
                                        }}>
                                            <img
                                                style={{
                                                    height: "2em",
                                                    width: "2em",
                                                    marginRight: "0.5em",
                                                }}
                                                src={require("../../asset/icc_patjar_istimewa.png")} />Patjar Istimewa
                                        </Card> : null
                                }

                                <div style={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "hidden",
                                    overflowY: "scroll",
                                    height: '100%',
                                }}>

                                    <div style={{
                                        fontSize: "1em",
                                        marginBottom: '10px'
                                    }}>{session.session_name}</div>


                                    <span style={{ fontSize: '0.75em', fontWeight: 'bold' }}>Bersama</span>
                                    <div style={{
                                        fontSize: "1em",
                                        marginBottom: '10px'
                                    }}>{session.session_speaker}</div>

                                    <span style={{ fontSize: '0.75em', fontWeight: 'bold' }}>Tanggal & Waktu</span>
                                    <div style={{
                                        fontSize: "1em",
                                        marginBottom: '10px'
                                    }}>
                                        {moment(session.start_time).locale('id').format("dddd, DD MMMM YYYY")}<br />
                                        {moment(session.start_time).locale('id').format("HH:mm")} - {moment(session.end_time).format("HH:mm")} {timeZoneCity}
                                    </div>
                                    {
                                        session.price !== 1 ? <>
                                            <span style={{
                                                fontSize: '0.75em',
                                                fontWeight: 'bold'
                                            }}>{info.type === "donation" ? "Donasi Minimum" : "HTM"}</span>
                                            <div style={{
                                                fontSize: "1em",
                                                marginBottom: '10px'
                                            }}>
                                                {this.renderPrice()}
                                            </div>
                                        </> : null
                                    }


                                    <span style={{ fontSize: '0.75em', fontWeight: 'bold' }}>Kuota</span>
                                    <h6 style={{
                                        fontSize: "1em",
                                        marginBottom: '10px'
                                    }}>{session.quota === 0 ? "Tidak ada Kuota" :
                                        `${session.quota} orang (${session.remaining_quota} Tersisa)`
                                        }</h6>

                                    <span style={{ fontSize: '0.75em', fontWeight: 'bold' }}>Deskripsi</span>
                                    <div style={{
                                        fontSize: "1em",
                                        marginBottom: '10px',
                                    }}>
                                        {formattedDescription}
                                    </div>


                                </div>

                                {(session.status === 'WAITING' && session.online_payment) || session.status === 'PENDING' || this.state.isPending ?
                                    <div style={{
                                        backgroundColor: '#fffeec',
                                        color: 'black',
                                        fontSize: '0.7em',
                                        border: '1px solid #ebd98b',
                                        borderRadius: 6,
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        textAlign: 'center',
                                        marginBottom: '3px'
                                    }}>
                                        {session.status === 'WAITING' && session.online_payment ? 'Bayar sebelum ' + moment(this.props.session.registration_info.modified_at).add(6, 'hours').format('DD MMM YYYY, HH.mm') : 'Kuota habis. Anda akan dikabari apabila kuota tersedia.'}
                                    </div> : null}

                                {session.status === "REJECTED" && session.online_payment ? <div style={{
                                    backgroundColor: '#fffeec',
                                    color: 'black',
                                    fontSize: '0.7em',
                                    border: '1px solid #ebd98b',
                                    borderRadius: 6,
                                    paddingTop: '3px',
                                    paddingBottom: '3px',
                                    textAlign: 'center',
                                    marginBottom: '3px'
                                }}>Anda tidak membayar tepat waktu, harap melakukan proses registrasi
                                    kembali</div> : null}

                                {/*{session.price === 0  ? <div style={{*/}
                                {/*    backgroundColor: '#fffeec',*/}
                                {/*    color: 'black',*/}
                                {/*    fontSize: '0.7em',*/}
                                {/*    border: '1px solid #ebd98b',*/}
                                {/*    borderRadius: 6,*/}
                                {/*    paddingTop: '3px',*/}
                                {/*    paddingBottom: '3px',*/}
                                {/*    textAlign: 'center',*/}
                                {/*    marginBottom: '3px'*/}
                                {/*}}>*/}
                                {/*    Kuota dibuat berdasarkan jumlah kursi tersedia. Kursi diprioritaskan untuk yang terdaftar. Jika kuota terpenuhi, tetapi patjarboekoe masih berminat mengikuti sesi ini, dipersilakan mendaftar dan datang. Selama merasa nyaman, patjarboekoe diperkenankan berdiri, duduk di lantai, atau membawa kursi lipat sendiri.*/}
                                {/*</div> : null}*/}


                                {this.renderQuantitySelectorButton()}
                                {this.renderAlertText()}
                                {this.renderActionButton()}

                            </div>

                        </Col>

                    </Row>
                </Modal.Body>

            </Modal>

            <Modal
                size="md"
                onHide={() => {
                    this.setState({
                        isTicketModalShown: false
                    })
                }}
                show={this.state.isTicketModalShown}
            >
                <Modal.Header closeButton>
                    Tiket {session.session_name}
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center', display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <QRCode value={`TIK${session.registration_info?.id}`} />
                        <br />
                        Tunjukan QR Code ini kepada patjarkita (panitia) yang bertugas ketika hendak memasuki ruangan atau area sesi.
                    </div>
                </Modal.Body>

            </Modal>
        </>
    }
}
