import React, {useEffect, useRef, useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageWrapper from "./PageWrapper";
import {Button} from "react-bootstrap";
import {isMobile} from "react-device-detect";
import {AiOutlineCalendar, AiOutlineWarning} from "react-icons/ai";
import Palette from "../../util/Palette";
import {MdError, MdLocationOn} from "react-icons/md";
import {TiLocationOutline, TiTicket} from "react-icons/ti";
import {useCookies} from "react-cookie";
import Session from "../../models/Session";
import KonserDongengTicketNode from "../reusable/KonserDongengTicketNode";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import KonserDongengGambar from "../../asset/konser-dongeng/konserdongengbanner.jpeg";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {FaInfoCircle} from "react-icons/fa";

export default function KonserDongeng(props) {
    const [isLoginFormShown, showLoginForm] = useState(false);
    const buyTicketRef = useRef(null);
    const [cookie, setCookie, removeCookie] = useCookies()
    const isEN = false
    const [papermoonSesssions, setPapermoonSessions] = useState([]);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const [isToCModalOpen, setIsToCModalOpen] = useState(false)
    const [isPPModalOpen, setIsPPModalOpen] = useState(false)

    function handleResize() {
        console.log('width: ' + window.innerWidth)

        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
        })
    }

    useEffect(() => {
        new Session().getKonserDongengSessions().then(res => {
            console.log(res)
            setPapermoonSessions(res);
        }).catch(err => console.log(err))

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }

        // ApplicationActivities.sendActivity({
        //     type: "PAPERMOON",
        //     description: "",
        //     timestamp: new Date(),
        //     os: isMobile ? "mobile-website" : "website"
        // })
    }, []);

    return (
        <PageWrapper
            loginShow={isLoginFormShown}
            customOnHide={isLoginFormShown ? () => showLoginForm(false) : null}
            headerActive={'konserdongeng'}
            hideFooter
        >
            <Container fluid>
                <Container style={{paddingTop: '1em', marginBottom: 70}}>
                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <img src={'/image/papermoon-header.jpg'} style={{width: '100%', maxWidth: 800}}/>*/}
                    {/*</Row>*/}


                    {/*<Row style={{display: 'flex', justifyContent: 'left'}}>*/}
                    {/*    <TiLocationOutline color={'#3EC200'} size={22}*/}
                    {/*                       style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>EVENT ONLINE</div>*/}

                    {/*    <div style={{height: 30, width: 2, backgroundColor: '#ebebeb', marginLeft: 15, marginRight: 15}}/>*/}

                    {/*    <TiTicket color={'#3EC200'} size={22}*/}
                    {/*              style={{marginRight: 10, marginTop: 2}}/>*/}
                    {/*    <div style={{fontWeight: 'bold'}}>{isEN ? 'US$ 10' : 'Rp100.000,-'}</div>*/}
                    {/*    <div style={{marginLeft: 3, color: 'red', opacity: isEN ? 1 : 0}}>*</div>*/}
                    {/*</Row>*/}

                    {/*{isEN ?*/}
                    {/*    <Row style={{*/}
                    {/*        display: 'flex',*/}
                    {/*        justifyContent: 'left',*/}
                    {/*        fontSize: 12,*/}
                    {/*        color: '#878787',*/}
                    {/*        marginTop: 5*/}
                    {/*    }}>*/}
                    {/*        /!*<Col style={{color: 'red', fontSize: 14, marginRight: 5, textAlign: 'right', paddingRight: 0}} xs={1} sm={2}>*!/*/}
                    {/*        /!*    **!/*/}
                    {/*        /!*</Col>*!/*/}
                    {/*        <Col style={{textAlign: 'left', }}>*/}
                    {/*            <span style={{marginRight: 5, color: 'red'}}>*/}
                    {/*                **/}
                    {/*            </span>*/}

                    {/*The price will be converted into rupiah with an exchange rate of 1 USD = IDR*/}
                    {/*15,000*/}
                    {/*        </Col>*/}
                    {/*        /!*<div>*</div>*!/*/}

                    {/*    </Row> : null*/}
                    {/*}*/}

                    <Row style={{display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: 25}}>
                        <img src={KonserDongengGambar}
                             style={{width: '100%', maxWidth: 900, objectFit: 'contain', maxHeight: 500}}/>
                    </Row>

                    {/*<div style={{marginTop: 15, marginBottom: 35, textAlign: 'left'}}>*/}

                    {/*    <p style={{*/}
                    {/*        fontSize: '1.1em',*/}
                    {/*        fontWeight: 'bolder'*/}
                    {/*    }}>{isEN ? 'TICKET PRICE' : 'HARGA TIKET'}</p>*/}

                    {/*<div style={{marginTop: -5}}>*/}
                    {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ? <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                    {/*</div>*/}

                    {/*{isEN ?*/}
                    {/*    <div style={{fontSize: 12, color: 'grey'}}>*/}
                    {/*        <span style={{color: 'red', marginRight: 3}}>*</span>*/}
                    {/*        The price will be converted into rupiah with an exchange rate of 1 USD = IDR 15,000*/}
                    {/*    </div> : null*/}
                    {/*}*/}
                    {/*</div>*/}

                    <Row style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style={{
                            marginTop: 15, textAlign: 'left', maxWidth: 900,
                            // background: `url('/image/beetle-down.png')`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "60px 60px",
                            backgroundPosition: "70% 5%",
                        }}>

                            {/*<p style={{*/}
                            {/*    fontSize: '1.3em',*/}
                            {/*    fontWeight: 'bolder'*/}
                            {/*}}>{isEN ? 'DESCRIPTION' : 'DESKRIPSI'}</p>*/}

                            <div>
                                {
                                    isEN ?
                                        <p>
                                            As part of discussions about “Puppetry and Ecology in Indonesia”,<br/>
                                            Papermoon Puppet Theatre and patjarmerah present
                                        </p>
                                        :
                                        <p>
                                            Ayo Dongeng Indonesia bekerjasama dengan patjarmerah mempersembahkan
                                        </p>
                                }

                            </div>

                            <div style={{
                                marginBottom: 35, textAlign: 'left',
                            }}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left',

                                }}>{isEN ? 'EVENT' : 'ACARA'}</p>

                                <div style={{marginTop: -5,}}>
                                    Konser Dongeng Kak aio
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'PRODUCED BY' : 'PRODUKSI'}</p>

                                <div style={{marginTop: -5}}>
                                    Ayo Dongeng Indonesia
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'DURATION' : 'DURASI'}</p>

                                <div style={{marginTop: -5}}>
                                    2 jam (dengan jeda istirahat)<br/>
                                    Menampilkan sejumlah dongeng pilihan dari koleksi dongeng Kak aio yang dibawakan di
                                    panggung-panggung
                                    festival dongeng internasional, cerita-cerita asal dongeng tersebut, dan bagaimana
                                    dongeng-dongeng itu
                                    dikembangkan.
                                </div>

                            </div>

                            <div
                                style={{
                                    // marginTop: 15,
                                    marginBottom: 35,
                                    // paddingBottom: 15,
                                    // paddingTop: 15,
                                    textAlign: 'left',
                                    // background: `url('/image/kumbang.png')`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "80px 80px",
                                    backgroundPosition: dimensions.width > 800 ? "40% 50%" : "60% 50%",
                                }}>

                                <div style={{
                                    marginBottom: 35, textAlign: 'left',

                                }}>

                                    <p style={{
                                        fontSize: '1.1em',
                                        fontWeight: 'bolder',
                                        textAlign: 'left'
                                    }}>{isEN ? 'DAY/DATE' : 'HARI/TANGGAL'}</p>

                                    <div style={{marginTop: -5}}>
                                        Minggu, 31 Juli 2022
                                    </div>

                                </div>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'LOCATION' : 'LOKASI'}</p>

                                <div style={{marginTop: -5}}>
                                    Luring – dia.lo.gue, Kemang, Jakarta<br/>
                                    Daring - Zoom
                                </div>

                            </div>

                            <div style={{marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'TICKET' : 'TIKET'}</p>

                                <div style={{marginTop: -5}}>
                                    Luring – IDR 150K<br/>
                                    Daring - IDR 75K

                                </div>

                            </div>

                            <div>


                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>
                                    {isEN ? "SYNOPSIS" : "SINOPSIS"}
                                </p>

                                Konser Dongeng Kak aio
                                <br/>

                                “Sesuatu yang Baik Perlu Dibagi” adalah dongeng-dongeng pilihan dari koleksi dongeng Kak
                                aio di panggung festival dongeng internasional.<br/><br/>

                                ---<br/><br/>

                                Ariyo Zidni atau Kak aio diundang mewakili Indonesia untuk mendongeng ke berbagai
                                negara: Malaysia, Singapura, Thailand, Vietnam, Korea Selatan, India, sampai Amerika
                                Serikat. Dongeng-dongengnya memiliki beragam gaya dan beberapa diantaranya merupakan
                                repertoire utama Kak aio. Kak aio mendongeng seperti layaknya seorang words stylist;
                                dibawakan tanpa alat bantu, hanya dengan kekuatan kata-kata. Ada dongeng rakyat
                                Indonesia, ada dongeng langka dunia, ada dongeng jari.

                                <br/><br/>---<br/><br/>

                                Dalam konser dongeng nanti, Kak aio juga akan membagi cerita rahasia tentang asal mula
                                dongeng-dongengnya dan ada cerita-cerita apa di balik dongeng-dongeng tersebut. Kak aio
                                juga tidak akan sendiri. Beberapa kawan Pendongeng dan para Penjaga Mimpi dari Ayo
                                Dongeng Indonesia turut hadir. Mereka akan menyajikan sebuah pertunjukan dongeng
                                sederhana yang menyenangkan dan berkesan untuk dinikmati.

                                <br/><br/>

                                Turut menampilkan Kak Widi Dwinanda, Kak BudiBaikBudi, Kak Hendra Hensem, dan Kak Ana &
                                Winnie.

                                <br/>

                                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {/*<img src={'/image/beetle.jpg'} style={{width: 40, height: 40}}/>*/}
                                </Row>

                            </div>
                            {/*{isEN ? "Is Supported By patjarmerah, Kotasis, dan Intellivent" : "Didukung oleh patjarmerah, Kotasis, dan Intellivent"}*/}

                            <div style={{marginTop: 30, marginBottom: 35, textAlign: 'left'}}>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontWeight: 'bolder',
                                    textAlign: 'left'
                                }}>{isEN ? 'SUPPORTED' : 'DIDUKUNG'}</p>

                                <div style={{marginTop: -5}}>
                                    Kementerian Komunikasi dan Informatika RI | SiBerkreasi | patjarmerah | Danone |
                                    AQUA | Nutricia | Sari Husada | dia.lo.gue | Bobo | Parenting is Easy | Roots |
                                    Turtale | Loading Squad | Erlangga For Kids
                                </div>

                            </div>
                        </div>


                        <div style={{
                            backgroundColor: 'white',
                            padding: 30,
                            borderRadius: 4,
                            border: '1px solid #d1d1d1',
                            width: "100%",
                            maxWidth: 900
                        }} ref={buyTicketRef}>
                            <p style={{
                                fontSize: '1.1em',
                                fontWeight: 'bold'
                            }}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}</p>

                            <p>
                                <span href={"#"} onClick={() => {
                                    setIsToCModalOpen(true)
                                }} style={{
                                    color: "#007bff",
                                    cursor: "pointer"
                                }}>
                                    {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                                </span>
                            </p>

                            <div>
                                {
                                    papermoonSesssions.map((session, idx) => {
                                        return <KonserDongengTicketNode
                                            isEN={isEN} session={session}
                                            lineSeparator={true}
                                            showLoginForm={showLoginForm}/>
                                    })
                                }
                                <div style={{
                                    textAlign: "Left"
                                }}>
                                    <Row style={{marginTop: 25}}>
                                        <Col style={{
                                            fontWeight: '600',
                                            display: 'flex',
                                            // alignItems: 'center',
                                            flexDirection: 'column',
                                            width: "100%"
                                        }} xs={12} md={7}>
                                            <div style={{
                                                width: "100%",
                                                fontWeight: 400
                                            }}>
                                                <b>Konser Dongeng Kak aio Live di dia.lo.gue (lebih dari 2 tiket)</b>
                                                <br/>

                                                <b>Rp150.000/tiket</b>
                                                <br/>
                                                Minggu, 31 Juli 2022 | 09:00 - 11:00<br/>

                                                {/*{moment(session.start_time).utc().add(7, 'hours').format('H:mm')} Yogya/BKK,*/}
                                                {/*{moment(session.start_time).utc().subtract(4, 'hours').format(' H:mm')} NYC,*/}
                                                {/*{moment(session.start_time).utc().add(2, 'hours').format(' H:mm')} Ams/Berlin,*/}
                                                {/*{moment(session.start_time).utc().add(9, 'hours').format(' H:mm')} Tokyo,*/}
                                                {/*{moment(session.start_time).utc().add(10, 'hours').format(' H:mm')} Melb,*/}
                                                {/*{moment(session.start_time).utc().add(8, 'hours').format(' H:mm')} Sing/Taipei/KL/HK*/}
                                            </div>


                                        </Col>

                                        <Col xs={12} md={5}>
                                            <Container>
                                                <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                    <a href={"https://wa.me/6281585705055?text=Hai.%0D%0ASaya+ingin+memesan+tiket+Konser+Dongeng+Kak+aio+pada+31+Juli+2022.%0D%0A%0D%0ANama%3A%0D%0AJumlah+tiket+%3A+%0D%0AE-mail%3A%0D%0A%0D%0ATerima+kasih."}><Button
                                                        style={{
                                                            fontWeight: 'bolder',
                                                            alignSelf: 'flex-start',
                                                            width: 125,
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                            margin: 15,
                                                            marginTop: 15,
                                                            // marginRight: 33,
                                                            marginBottom: 7,
                                                        }}
                                                        variant={'primary'}
                                                    >
                                                        BELI
                                                    </Button></a>
                                                </Row>

                                            </Container>
                                        </Col>
                                    </Row>
                                </div>
                            </div>


                            {
                                isEN ?
                                    <>
                                        <Row style={{
                                            height: 1,
                                            backgroundColor: '#e6e6e6',
                                            marginTop: 20,
                                            marginBottom: 20
                                        }}/>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-end",
                                            justifyContent: "flex-end"
                                        }}>
                                            <Button
                                                variant={"outline-primary"}
                                                onClick={() => {
                                                    setIsPPModalOpen(true)
                                                }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}>
                                                    <img src={require("../../asset/paypal-logo.png")}
                                                         style={{height: "1em"}}/>
                                                    <div style={{marginLeft: "1em"}}>Pay With Paypal</div>
                                                </div>
                                            </Button>
                                        </div>

                                    </>
                                    :
                                    null
                            }


                        </div>

                    </Row>
                    {
                        isMobile ?
                            <Row>
                                <div>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </Row>
                            :
                            null
                    }


                </Container>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsToCModalOpen(false)
                    }}
                    show={isToCModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Terms and Condition" : "Syarat dan Ketentuan"}
                    </Modal.Header>
                    <Modal.Body>
                        <ul style={{marginBotton: "42px", lineHeight: "30px"}}>
                            <li>
                                {isEN ? "" : "1 tiket luring (offline) berlaku untuk 1 orang."}
                            </li>
                            <li>
                                {isEN ? "" : "Apabila anak ditemani oleh pendamping, pendamping juga harus membeli tiket."}
                            </li>
                            <li>
                                {isEN ? "All Sales are Final." : "Transaksi yang dilakukan bersifat final."}
                            </li>
                            <li>
                                {isEN ? "Items that have been purchased cannot be returned." : "Tiket yang dibeli tidak bisa ditukar atau dikembalikan."}
                            </li>
                            <li>
                                {isEN ? "" : "Harap menyelesaikan pembayaran dalam waktu 2 jam."}
                            </li>
                            <li>
                                {isEN ? "" : "Pemesanan dibatalkan secara otomatis apabila dalam waktu 2 jam pembayaran tidak dilakukan."}
                            </li>
                        </ul>
                    </Modal.Body>

                </Modal>

                <Modal
                    size="lg"
                    onHide={() => {
                        setIsPPModalOpen(false)
                    }}
                    show={isPPModalOpen}
                >
                    <Modal.Header closeButton>
                        {isEN ? "Pay With Paypal" : "Pembayaran Menggunakan Paypal"}
                    </Modal.Header>

                    <Modal.Body>
                        As an alternative method to accomodate overseas international payment, we now accept payment
                        using
                        Paypal.<br/>

                        <br/>
                        <br/>

                        <b>How to Confirm Your Paypal Payment</b>
                        <ol>
                            <li>
                                Create an account in patjarmerah.com
                            </li>
                            <li>
                                Pay with Paypal using the following <a
                                href={"https://www.paypal.com/paypalme/papermoonpuppet"}
                                target={"_blank"}>link</a>
                            </li>
                            <li>
                                Confirm your payment via email to <a
                                href="mailto: renjana@patjarmerah.com">renjana@patjarmerah.com</a>.
                                In the email, please include your <b>payment proof</b>, registered <b>email</b>, and
                                your <b>preferred
                                show time</b>. Please also use the subject <b>Paypal Payment Confirmation</b>.
                            </li>
                            <li>
                                Please wait for our admins to process your payment and we will get back to you.
                            </li>
                            {/*<li>*/}
                            {/*    {isEN ? "Please complete your payment 2 hours within order." : "Harap menyelesaikan pembayaran 2 jam setelah pendaftaran."}*/}
                            {/*</li>*/}
                        </ol>

                        <br/>

                        <b>Disclaimer</b>

                        <ol>
                            <li>
                                Please be aware that payment using paypal is confirmed manually by our admins, so it may
                                takes several
                                hours before confirmation.
                            </li>
                            <li>
                                If your preferred show time is full, we will put you in another time.
                            </li>
                            <li>
                                We recommend you to pay using other method first before using paypal.
                            </li>
                        </ol>
                    </Modal.Body>

                </Modal>

                {
                    !isMobile ?
                        <Row style={{
                            position: 'fixed',
                            bottom: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            borderTop: '1px solid #e6e6e6',
                            paddingTop: 25,
                            paddingBottom: 30,
                            paddingRight: 20,

                        }}
                        >
                            <Col style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'flex-end',
                                flexDirection: 'row'
                            }}>

                                {/*<div style={{*/}
                                {/*    marginTop: -5,*/}
                                {/*    display: "flex",*/}
                                {/*    flexDirection: "column",*/}
                                {/*    justifyContent: "center",*/}
                                {/*    height: "100%",*/}
                                {/*    marginRight: 10*/}
                                {/*}}>*/}

                                {/*    <img*/}
                                {/*        src={'/image/kumbang.png'}*/}
                                {/*        style={{*/}
                                {/*            width: 60, height: 60,*/}
                                {/*            transform: 'rotate(97deg)'*/}
                                {/*        }}/>*/}

                                {/*</div>*/}

                                {/*<div style={{*/}
                                {/*    marginTop: -5,*/}
                                {/*    display: "flex",*/}
                                {/*    flexDirection: "column",*/}
                                {/*    justifyContent: "center",*/}
                                {/*    height: "100%",*/}
                                {/*    fontWeight : "bold",*/}
                                {/*    marginRight : 15*/}
                                {/*}}>*/}
                                {/*    {isEN ? 'USD 10' : 'IDR 100.000'} {isEN ?*/}
                                {/*    <span style={{color: 'red'}}>*</span> : null} {isEN ? 'or' : 'atau'} Barter*/}
                                {/*</div>*/}


                                <Button style={{
                                    fontWeight: 'bolder',
                                    fontSize: '1.05em',
                                    // backgroundColor: '#cf484f',
                                    // borderWidth: 0,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    margin: 3,
                                    width: 150,
                                }}
                                        onClick={() => buyTicketRef.current.scrollIntoView({behavior: 'smooth'})}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}
                                </Button>
                                {/*<Button style={{*/}
                                {/*    fontWeight: 'bolder',*/}
                                {/*    fontSize: '1.05em',*/}
                                {/*    width: 150,*/}
                                {/*    paddingTop: 10,*/}
                                {/*    paddingBottom: 10,*/}
                                {/*    margin: 3*/}
                                {/*}} onClick={() => window.open('https://forms.gle/WhtE6prUEbTaBE5w9')}*/}
                                {/*        variant={'outline-primary'}>*/}
                                {/*    BARTER*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                        :
                        <Row style={{
                            position: 'fixed',
                            bottom: 0,
                            backgroundColor: 'white',
                            width: '100%',
                            borderTop: '1px solid #e6e6e6',
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingRight: 20,
                        }}>
                            {/*<Col xs={2} style={{*/}
                            {/*  display: "flex",*/}
                            {/*  alignItems: "center",*/}
                            {/*  justifyContent: "center"*/}
                            {/*}}>*/}

                            {/*</Col>*/}
                            <Col xs={12}>
                                <Button
                                    style={{
                                        fontWeight: 'bolder',
                                        fontSize: '1.05em',
                                        // backgroundColor: '#cf484f',
                                        // borderWidth: 0,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        margin: 3,
                                        width: "100%"
                                    }}
                                    onClick={() => buyTicketRef.current.scrollIntoView({behavior: 'smooth'})}>{isEN ? 'BUY TICKET' : 'BELI TIKET'}
                                </Button>
                                {/*<Button*/}
                                {/*    style={{*/}
                                {/*        fontWeight: 'bolder',*/}
                                {/*        fontSize: '1.05em',*/}
                                {/*        paddingTop: 10,*/}
                                {/*        paddingBottom: 10,*/}
                                {/*        margin: 3,*/}
                                {/*        width: "100%"*/}
                                {/*    }} onClick={() => window.open('https://forms.gle/WhtE6prUEbTaBE5w9')}*/}
                                {/*    variant={'outline-primary'}>*/}
                                {/*    BARTER*/}
                                {/*</Button>*/}
                            </Col>
                        </Row>
                }


            </Container>
        </PageWrapper>
    )
}
