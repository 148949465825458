import Container from "react-bootstrap/Container";
import React, {useEffect} from "react";
import PageWrapper from "./PageWrapper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";
import Palette from "../../util/Palette";

export default function KataPatjar(props) {
    const quotes = [
        {
            content: 'Menurut saya, patjarmerah adalah sebuah perlawanan. Perlawanan kepada pola-pola pameran buku yang monoton, kepada gedung yang mewah dan nyaman, kepada buku yang mahal dan harganya tak terjangkau. Perlawanan sejenis ini harus banyak dan dilakukan bersama-sama.',
            image: 'image/foto-seno.JPG',
            originator: 'Seno Gumira Ajidarma',
            originatorJobs: 'penulis dan sastrawan'
        },
        {
            content: 'Hangat rasanya melihat pasar buku dan festival literasi ini berkeliling ke kota-kota lain ibarat sirkus keliling. patjarmerah adalah perayaan, pertanyaan, pernyataan, kesempatan, dan tentu saja … pertemuan.',
            image: 'image/foto-ria.jpg',
            originator: 'Ria Papermoon',
            originatorJobs: 'pencerita, seniman, founder Papermoon Puppet'
        },
        {
            content: 'Namanya jelas keren, ‘patjarmerah’, desainnya bagus. Namun, yang terpenting dalam pasar buku seperti ini adalah acaranya. Jangan sampai kualitas literasi acaranya tidak bagus. Nah, patjarmerah menjawab itu dengan baik, jadi antara jualan dengan konten yang ada di panggung dan lokakarya itu menarik semua.',
            image: 'image/foto-puthut.jpg',
            originator: 'Puthut EA',
            originatorJobs: 'penulis'
        },
        {
            content: 'patjarmerah baru saja usai, tetapi saya sudah rindu dengan suasananya dan para pencinta bukunya. patjarmerah memang candu!',
            image: 'image/foto-ivan.jpg',
            originator: 'Ivan Lanin',
            originatorJobs: 'pegiat bahasa Indonesia, wikipediawan, penulis'
        },
        {
            content: 'Rasanya tidak ada acara perbukuan seperti patjarmerah, yang berkeliling menjemput bola mengunjungi pembaca, sambil memadukan diskusi-diskusi bernas, temu penulis yang hangat, dengan upaya penjualan yang masif.',
            image: 'image/foto-ronny.jpg',
            originator: 'Ronny Agusinus',
            originatorJobs: 'founder Marjin Kiri'
        },
        {
            content: 'Dua kali ke hajatan patjarmerah, dua kali dibuat terpuaku: bahwa buku (seperti halnya radio ketika muncul televisi) sepertinya tidak akan pernah mati karena ditinggal pembacanya. patjarmerah adalah sebuah statement bahwa dunia digital tidak menggantikan buku. Ia justru pintu masuk bagi mereka yang ingin mengakses sumber pengetahuan dan ekspresi dalam medium lain yang lebih intim dan personal.',
            image: 'image/foto-dandhy.jpg',
            originator: 'Dandhy Dwi Laksono',
            originatorJobs: 'jurnalis, penulis, dan sutradara film dokumenter'
        },
        {
            content: 'Meningkatkan minat baca artinya memperbanyak akses publik terhadap bacaan baik. patjarmerah adalah usaha untuk melakukan hal tersebut. Sebuah festival yang mempertemukan pembaca buku dan membuat buku menjadi lebih dari sekadar bacaan, patjarmerah menjadi semangat yang penting untuk diteruskan dan ditularkan.',
            image: 'image/foto-irfan.jpg',
            originator: 'Irfan Ramli',
            originatorJobs: 'penulis skenario layar lebar'
        },
        {
            content: 'patjarmerah adalah inisiatif literasi yang punya visi progresif dengan perspektif luas. Orang-orang di baliknya tahu cara memperlakukan buku, penulis, dan pembaca dengan baik. Mereka tidak hanya menggelar acara, tetapi juga membangun ekosistem.',
            image: 'image/foto-adimas.jpeg',
            originator: 'Adimas Imanuel',
            originatorJobs: 'penyair'
        },
        {
            content: 'patjarmerah adalah pesta literasi yang dipimpin oleh hikmat kegotongroyongan dalam semangat keakraban dan kegembiraan.',
            image: 'image/foto-joko.jpg',
            originator: 'Joko Pinurbo',
            originatorJobs: 'penyair'
        },
        {
            content: 'Bersenang-senang bersama buku dan manusia-manusia yang memilih bergembira bersama buku-buku.',
            image: 'image/foto-kalis.jpg',
            originator: 'Kalis Mardiasih',
            originatorJobs: 'penulis'
        },
        {
            content: 'Pasar buku murah, tetapi acaranya nggak murahan!',
            image: 'image/foto-trinity.jpg',
            originator: 'Trinity Traveler',
            originatorJobs: 'penulis'
        },
        {
            content: 'Seru dan ramai. Semoga bisa selalu diadakan setiap tahunnya dan semakin gede. Bukan hanya buku-bukunya murah, forum-forumnya juga asyik.',
            image: 'image/foto-ismail.jpg',
            originator: 'Ismail Basbeth',
            originatorJobs: 'sutradara layar lebar'
        },
        {
            content: 'Seperti mimpi, ratusan bahkan ribuan buku dengan harga yang tidak masuk akal murahnya dapat ditemukan. Jajaran ‘buku indie’ sampai buku-buku pelajaran yang tersusun dengan gemas, membawa kita -yang doyan baca- seperti mendapati harta karun: kebanyakan, tidak mau pulang, tidak mau keluar dari gua.',
            image: 'image/foto-theoresia.jpeg',
            originator: 'Theoresia Rumthe',
            originatorJobs: 'penyair'
        },
        {
            content: 'patjarmerah buat aku adalah festival literasi yang inklusif. Semua orang diundang untuk merayakan kegemaran membaca, tak peduli bacaan favoritmu apa. Semoga patjarmerah benar-benar menjelma menjadi sirkus keliling dan menghibur Indonesia, di tempat yang tak terduga.',
            image: 'image/foto-alexander.jpg',
            originator: 'Alexander Thian',
            originatorJobs: 'penulis dan fotografer'
        },
        {
            content: 'patjarmerah adalah festival literasi yang sangat hangat dan penuh cinta kepada buku, penulis, dan pembaca.',
            image: 'image/foto-reda.jpeg',
            originator: 'Reda Gaudiamo',
            originatorJobs: ' penulis dan pemusik'

        },
        {
            content: 'Kelompok sirkus ini menyenangkan sekali. Saat memperhatikannya dari jauh, juga saat mengunjunginya, terasa sekali betapa mereka mencintai buku-buku, menyayangi para pembaca, dan punya energi yang besar untuk mengajak semua orang bergembira. Sehat-sehat terus, ya, teman-teman patjarmerah, nanti main lagi.',
            image: 'image/foto-post.jpg',
            originator: 'Teddy & Maesy',
            originatorJobs: 'founder Post Santa dan Post Press'
        }
    ]


    useEffect(() => {
        ApplicationActivities.sendActivity({
            type: "KATA_PATJAR",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "Mobile Webiste" : "Website"
        })
    }, [])

    return (
        <PageWrapper headerActive={"kata-patjar"}>
            <Container style={{marginTop: 80}}>
                <Row style={{display: 'flex', justifyContent: 'center'}}>
                    <span style={{fontSize: 24, fontWeight: 'bold'}}>KATA PATJAR</span>
                </Row>

                <Row style={{display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 80}}>
                    <span style={{width: 80, height: 4, backgroundColor: Palette.MAROON, borderRadius: 5}}/>
                </Row>

                <Row>
                    {quotes.map(quote => {
                        return (
                            <Container style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}>
                                <Row>
                                    <img src={quote.image} style={{
                                        width: 76,
                                        height: 76,
                                        borderRadius: 38,
                                        border: '2px solid #dedede'
                                    }}/>
                                </Row>

                                <Row>
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: '#c2c2c2'
                                    }}>{quote.originator}</div>
                                </Row>

                                <Row>
                                    <div style={{
                                        fontWeight: '300',
                                        fontSize: '0.95em',
                                        marginLeft: 50,
                                        marginRight: 50,
                                        textAlign: 'center',
                                        textHeight: 200,
                                        height: '3em',
                                        lineHeight: '1.5em'
                                    }}>{quote.originatorJobs}</div>
                                </Row>

                                <p style={{
                                    fontSize: '2em',
                                    color: Palette.MAROON,
                                    marginTop: -10,
                                    marginBottom: -20,
                                    alignSelf: 'flex-start'
                                }}>❝</p>

                                <Row
                                    style={{
                                        textAlign: 'center',
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        fontWeight: '600',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    {quote.content}
                                </Row>

                                <p style={{
                                    fontSize: '2em',
                                    color: Palette.MAROON,
                                    marginTop: -20,
                                    alignSelf: 'flex-end'
                                }}>❞</p>

                                <div style={{width: '100%', height: 1, marginBottom: 20, backgroundColor: '#c2c2c2'}}/>
                            </Container>

                        )
                    })}


                </Row>
            </Container>
        </PageWrapper>
    )
}
