import React, {useEffect, useState} from "react";
import PageWrapper from "./PageWrapper";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Palette from "../../util/Palette";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import GlobalData from "../../util/GlobalData";

import ApplicationActivities from "../../models/ApplicationActivity";
import {isMobile} from "react-device-detect";

export default function Donation(props) {
    const [campaigns, setCampaigns] = useState([
        {
            title: 'Ayo Bersama Cegah Covid-19',
            image: require('../../asset/kitabisa_banner.jpg'),
            short_url: 'patjarmerahbisa'
        }
    ]);

    useEffect(() => {
        // getCampaign();
        ApplicationActivities.sendActivity({
            type: "DONATION",
            description: "",
            timestamp: new Date(),
            os: isMobile ? "mobile-website" : "website"
        })
    }, [])

    const set = async (endpoint, method, body) => {
        let response = await fetch(endpoint, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: GlobalData.token ? `bearer ${GlobalData.token}` : null,
                Accept: 'application/json',
            },
            body: JSON.stringify(body)
            // body: "afdadsf"
        });

        if (response.ok) {
            return response.json()
        } else {
            let error = await response.json()

            console.log(error)

            throw error
        }
    }

    const getCampaign = async () => {
        const response = await set('https://core.ktbs.io/campaigns?category_id=22', 'GET');

        const campaignsCopy = [...campaigns];

        for (let idx = 0; idx < 3; idx++) {
            campaignsCopy.push(response.data[idx]);
        }

        setCampaigns(campaignsCopy);
        console.log(campaignsCopy)
    }

    function thousandSeparator(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <PageWrapper
            headerShy={false}
            headerActive={'donation'}>
            <Container fluid style={{marginBottom: '35px'}}>
                <Row style={{backgroundColor: '#000000E6'}}>
                    <Col>
                        <img src={require('../../asset/donation-bg.jpg')}
                             style={{objectFit: 'cover', width: '100%', height: '50vh'}}/>
                        <div style={{
                            position: 'absolute',
                            backgroundColor: '#000000B3',
                            height: '50vh',
                            width: '100%',
                            top: 0,
                            left: 0,
                            paddingTop: '30vh',
                        }}>
                            <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
                                <Container>
                                    <Row style={{
                                        color: 'white',
                                        fontSize: '40px',
                                        fontWeight: 'bold',
                                        padding : "0.2em"
                                    }}>#marisalingjaga</Row>

                                    <Row style={{
                                        color: 'white',
                                        fontSize: '20px',
                                        marginTop: '15px',
                                        marginBottom: '20px',
                                        padding : "0.5em"
                                    }}><b><b>Melawan COVID-19&nbsp;</b>dan Saling Jaga. APD GRATIS&nbsp;</b>untuk
                                        rumah sakit dan yankes di seluruh Indonesia</Row>
                                </Container>
                            </div>
                        </div>
                    </Col>
                </Row>


                <Container>
                    {/*<Row>*/}
                    {/*    <div style={{marginTop: '25px', marginBottom: '25px', fontSize: '27px', fontWeight: '600', color: '#303030'}}>Penggalangan Dana #BersamaLawanCorona</div>*/}
                    {/*</Row>*/}

                    <Row style={{height: "2em"}}/>
                    <Row style={{
                        display : "flex",
                        alignItems : "center",
                        justifyContent : "center"
                    }}>
                        {campaigns.map(campaign => {
                            return (
                                <Col xs={12} lg={6} style={{
                                    marginBottom: '25px',
                                }}>
                                    <Card>
                                        <Card.Img src={campaign.image}/>
                                        <Card.Body>
                                            <div style={{
                                                fontSize: '18px',
                                                height: '2.6em',
                                                lineHeight: '1.3em',
                                                fontWeight: 'bold'
                                            }}>{campaign.title}</div>
                                            {/*<div style={{fontSize: '16px', color: '#787878', marginTop: '3px'}}>*/}
                                            {/*    {campaign.campaigner}*/}
                                            {/*</div>*/}
                                            {/*<div style={{fontSize: '17px', marginTop: '3px'}}>Rp {thousandSeparator(campaign.donation_received)}</div>*/}

                                            {/*<ProgressBar now={campaign.donation_percentage * 100} style={{height: '5px', marginTop: '30px', marginBottom: '8px'}}/>*/}
                                            {/*<Row>*/}
                                            {/*    <Col md={6} xs={6}style={{fontSize: '16px'}}><span style={{fontWeight: '600', fontSize: '17px'}}>{thousandSeparator(campaign.donation_count)}</span> donasi</Col>*/}
                                            {/*    <Col md={6} xs={6} style={{fontSize: '16px', display: 'flex', justifyContent: 'flex-end'}}><span style={{fontWeight: '600', fontSize: '17px', marginRight: '4px', marginTop: '-2px'}}>{campaign.days_remaining}</span> hari lagi</Col>*/}
                                            {/*</Row>*/}

                                            Telah Terkumpul : Rp26.822.875
                                            <Button style={{
                                                width: '100%',
                                                marginTop: '20px',
                                                fontSize: '17px',
                                                backgroundColor: Palette.MAROON,
                                                borderWidth: 0
                                            }} disabled
                                                    onClick={() => window.open('https://kitabisa.com/campaign/' + campaign.short_url, '_blank')}>Donasi telah Berakhir</Button>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row style={{
                        display : "flex",
                        alignItems : "center",
                        justifyContent : "center"
                    }}>
                        <Col style={{
                            fontSize: "1em",
                            marginBottom: '10px',
                            textAlign : "justify"
                        }} xs={12} lg={6}>
                            patjarboekoe,
                            <br/>
                            <br/>
                            Kita saat ini sedang menghadapi sebuah realitas baru akibat penyebaran Covid-19. Penyebaran
                            virus corona secara masif di seluruh dunia telah memakan banyak korban jiwa, termasuk dari
                            kalangan tenaga medis. Setidaknya, di Indonesia sendiri, 31 tenaga medis wafat selama
                            pandemi COVID-19. Salah satu pemicunya, kelangkaan alat pelindung diri (APD).

                            <br/>
                            <br/>
                            Angka ini akan terus merangkak naik jika kita tidak melakukan apa-apa, padahal para tenaga
                            medis adalah garda depan pertahanan kita. "Buat ini perang yang bisa kami menangkan, bukan
                            misi bunuh diri. Terima kasih,” kata Tya, seorang dokter di RSUD WZ Yohannes, Kupang, Nusa
                            Tenggara Timur, kepada sebuah media. Bagi kami, membiarkan mereka memasuki ‘medan tempur’
                            tanpa peralatan adalah salah satu bentuk kejahatan kemanusiaan.

                            <br/>
                            <br/>
                            Karenanya, kami mengajak patjarboekoe untuk membuat perang ini bisa kita menangkan
                            bersama-sama lewat penggalangan dana #marisalingjaga. Kami akan menyalurkan donasi yang
                            terkumpul kepada Gerakan bersama para penjahit di Jogja, Majelis Mau Jahitin (MAMAJAHIT),
                            sebagai upaya gotong royong membantu penyediaan alat pelindung diri (APD) bagi paramedis
                            yang bertugas. APD yang dibuat berupa pakaian hazmat menggunakan jenis kain yang
                            direkomendasikan. APD dibagikan secara gratis kepada rumah sakit/yankes yang membutuhkan di
                            seluruh Indonesia. Gerakan MAMAJAHIT diinisiasi oleh para seniman, di antaranya @paksiraras
                            @santizaidan @killtheDJ. Info lengkap sila cek mamajahit.id

                            <br/>
                            <br/>

                            <img style={{width:"100%"}} src={require("../../asset/donasi1.jpg")}/>

                            <br/>
                            <br/>

                            <img style={{width:"100%"}} src={require("../../asset/donasi2.jpg")}/>

                            <br/>
                            <br/>

                            <img style={{width:"100%"}} src={require("../../asset/donasi3.jpg")}/>

                            <br/>
                            <br/>

                            <img style={{width:"100%"}} src={require("../../asset/donasi4.jpg")}/>

                        </Col>
                    </Row>
                </Container>
            </Container>
        </PageWrapper>
    )
}
